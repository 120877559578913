import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-letter',
  templateUrl: './news-letter.component.html',
  styleUrls: ['./news-letter.component.scss']
})
export class NewsLetterComponent implements OnInit {

  constructor() { }

  userEmail: string = 'who@wdo.se'
  userFirstname: string = 'peter'
  userLastname: string = 'parker'

  ngOnInit(): void {
  }

  onKeyEmail(value: string) {
    this.userEmail = value;
  }
  onKeyFirstname(value: string) {
    this.userFirstname = value;
  }
  onKeyLastname(value: string) {
    this.userLastname = value;
  }

}
