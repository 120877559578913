<div class="case-top-meta">
    <div class="meta">
        <div class="item">
            <div class="label font_12_55_Roman_p150">Vår roll</div>
            <div class="value font_12_65_Medium_p15">{{section.role}}</div>
        </div>
        <div class="item">
            <div class="label font_12_55_Roman_p150">Period</div>
            <div class="value font_12_65_Medium_p15" *ngIf="section.period.started">{{section.period.started}} -<span *ngIf="section.period.ended"> {{section.period.ended}}</span></div>
        </div>
        <div class="item">
            <div class="label font_12_55_Roman_p150">Länkar</div>
            <div class="value font_12_65_Medium_p15">
                <ng-container *ngFor="let link of section.links; let i = index">
                    <a href="{{link.value}}">{{link.label}}</a><span *ngIf="i < (section.links.length - 1)"> / </span>
                </ng-container>
            </div>
        </div>
        <div class="item">
            <div class="label font_12_55_Roman_p150">Frågor</div>
            <div class="value font_12_65_Medium_p15">

                <ng-container *ngFor="let link of section.contacts; let i = index">
                    <a href="{{link.value}}">{{link.label}}</a><span *ngIf="i < (section.contacts.length - 1) "> / </span>
                </ng-container>
            </div>

        </div>
    </div>
    <div class="description font_22_55_Roman_p20">{{section.description}}</div>
</div>