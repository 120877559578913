<div class="case-top-gallery">
    <div class="media">
        <ng-container *ngFor="let item of slides; let i = index">
            <div class="image" [ngStyle]="{'background-image': 'url( ' + item.image + ')'}" *ngIf="i == currentSlide"></div>
        </ng-container>
    </div>
    <div class="preheader">Kund</div>
    <div class="title">Skellefteå kommun</div>
    <div class="positions">
        <div class="positioner">
            <ng-container *ngFor="let slide of slides; let i = index">
                <div class="position" (click)="showSlide(i)" [ngClass]="{'active' : currentSlide == i}">
                    <div class="label" *ngIf="currentSlide < 10">0{{i + 1}}</div>
                    <div class="label" *ngIf="currentSlide > 9">{{i + 1}}</div>
                    <div class="time">
                        <div class="bar"></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>