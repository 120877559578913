import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ShowcaseComponent } from './showcase/showcase.component';
import { TeamComponent } from './team/team.component';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { TextContentComponent } from './text-content/text-content.component';
import { PageCoverComponent } from './page-cover/page-cover.component';
import { LargePushComponent } from './large-push/large-push.component';
import { VideoComponent } from './video/video.component';
import { AppNavigationComponent } from './app-navigation/app-navigation.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { AppContainerComponent } from './app-container/app-container.component'

import { NavigationService } from './shared/services/navigation.service';
import { ScrollviewService } from './shared/services/scrollview.service';
import { VerticalLinesComponent } from './vertical-lines/vertical-lines.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { BrandHeaderComponent } from './brand-header/brand-header.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { MediaSliderComponent } from './media-slider/media-slider.component';
import { WorkComponent } from './work/work.component';
import { LandingpageCoverComponent } from './landingpage-cover/landingpage-cover.component';
import { CasesComponent } from './cases/cases.component';
import { TeamMembersComponent } from './team-members/team-members.component';
import { AboutCoverComponent } from './about-cover/about-cover.component';
import { OffersComponent } from './offers/offers.component';
import { AboutVideoComponent } from './about-video/about-video.component';
import { SectionTopComponent } from './section-top/section-top.component';
import { CaseTopGalleryComponent } from './case-top-gallery/case-top-gallery.component';
import { CaseTopMetaComponent } from './case-top-meta/case-top-meta.component';
import { CaseGalleryComponent } from './case-gallery/case-gallery.component';
import { CaseArtflowComponent } from './case-artflow/case-artflow.component';
import { CaseMediaComponent } from './case-media/case-media.component';
import { CaseTopVideoComponent } from './case-top-video/case-top-video.component';
import { MainNavigationComponent } from './main-navigation/main-navigation.component';
import { PopupVideoComponent } from './popup-video/popup-video.component';
import { CaseTextComponent } from './case-text/case-text.component';
import { CaseVideoTextSplitComponent } from './case-video-text-split/case-video-text-split.component';
import { GhostlinkComponent } from './ghostlink/ghostlink.component';
import { PageComponent } from './page/page.component';
import { SectionsModelsComponent } from './sections-models/sections-models.component';
import { SectionTopModelComponent } from './sections-models/models/section-top-model/section-top-model.component';
import { PanesComponent } from './panes/panes.component';
import { CasePhotogridComponent } from './case-photogrid/case-photogrid.component';
import { CaseImageTextSplitComponent } from './case-image-text-split/case-image-text-split.component';
import { CaseImageTextSplashComponent } from './case-image-text-splash/case-image-text-splash.component';
import { ElementVideoBackgroundComponent } from './element-video-background/element-video-background.component';
import { PopupLayerComponent } from './popup-layer/popup-layer.component';
import { PopupTeamMembersComponent } from './popup-team-members/popup-team-members.component';
import { NewsLetterComponent } from './news-letter/news-letter.component';
import { ContactCoverComponent } from './contact-cover/contact-cover.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PopupPrivacyComponent } from './popup-privacy/popup-privacy.component';
import { TestrouteComponent } from './testroute/testroute.component';


// Implement HashLocationStrategy
import { HashLocationStrategy, LocationStrategy } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    ShowcaseComponent,
    TeamComponent,
    SectionHeaderComponent,
    PageHeaderComponent,
    TextContentComponent,
    PageCoverComponent,
    LargePushComponent,
    VideoComponent,
    AppNavigationComponent,
    NavigationBarComponent,
    AppContainerComponent,
    VerticalLinesComponent,
    PageFooterComponent,
    BrandHeaderComponent,
    PageTitleComponent,
    MediaSliderComponent,
    WorkComponent,
    LandingpageCoverComponent,
    CasesComponent,
    TeamMembersComponent,
    AboutCoverComponent,
    OffersComponent,
    AboutVideoComponent,
    SectionTopComponent,
    CaseTopGalleryComponent,
    CaseTopMetaComponent,
    CaseGalleryComponent,
    CaseArtflowComponent,
    CaseMediaComponent,
    CaseTopVideoComponent,
    MainNavigationComponent,
    PopupVideoComponent,
    CaseTextComponent,
    CaseVideoTextSplitComponent,
    GhostlinkComponent,
    PageComponent,
    SectionsModelsComponent,
    SectionTopModelComponent,
    PanesComponent,
    CasePhotogridComponent,
    CaseImageTextSplitComponent,
    CaseImageTextSplashComponent,
    ElementVideoBackgroundComponent,
    PopupLayerComponent,
    PopupTeamMembersComponent,
    NewsLetterComponent,
    ContactCoverComponent,
    PrivacyComponent,
    PopupPrivacyComponent,
    TestrouteComponent    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [
    NavigationService,
    ScrollviewService,
    //{provide: LocationStrategy, useClass: HashLocationStrategy} // implement HashLocationStrategy
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
