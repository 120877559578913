<div class="about-video">
    <div class="positioner">
        <div class="video">

            <app-element-video-background [fullscreen]="false" [style]="'none'" [video]="section.video">
            </app-element-video-background>

            <div class="decoration">
                <div class="tr"></div>
                <div class="bl"></div>
            </div>

        </div>
    </div>
</div>