import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from'../shared/services/navigation.service'

@Component({
  selector: 'app-landingpage-cover',
  templateUrl: './landingpage-cover.component.html',
  styleUrls: ['./landingpage-cover.component.scss']
})
export class LandingpageCoverComponent implements OnInit {

  @Input() section: any;

  constructor(public navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  openPopup() {
    let pop = {
      type: 'youtube',
      id: 'home'
    }
    this.navigationService.openPopup(pop);
  }
  
}
