<div class="work">
    <div class="positioner">
        <div class="title">Lediga tjänster</div>
        <div class="menu">
            <div class="menu-items">
                <div class="menu-item" (click)="setItem(0)" [ngClass]="{'active' : currentItem == 0}">Projektansvarig
                </div>
                <div class="menu-item" (click)="setItem(1)" [ngClass]="{'active' : currentItem == 1}">Formgivare</div>
                <div class="menu-item" (click)="setItem(2)" [ngClass]="{'active' : currentItem == 2}">AD</div>
                <div class="menu-item" (click)="setItem(3)" [ngClass]="{'active' : currentItem == 3}">Developer</div>
            </div>
        </div>
        <div class="information">



            <div class="item" [@fadein] *ngIf="currentItem == 2">
              <!--   <div class="title">Art Director</div> -->
                <div class="header">Bakgrund</div>
                <div class="text">
                    Är du en kreativ, formsäker och noggrann Art Director som är bekväm med att arbeta med olika former
                    av
                    digitala produktioner? Då kan tjänsten som AD hos WDO passa dig perfekt. Vi har en bred palett av
                    kunder
                    inom offentlig och privat verksamhet och därmed en väldigt varierad uppdragsbild. Vi är nyfikna både
                    på
                    vad du har gjort och vem du är, så din portfölj bifogar du självklart din ansökan.
                </div>
                <div class="header">Art Director</div>
                <div class="text">

                    <em>Dina arbetsuppgifter</em>
                    <ul>
                        <li class="li">
                            Arbeta fram olika former av digitala produkter som hemsidor, appar och tjänster
                        </li>
                        <li class="li">
                            Viss formgivning för tryck kan förekomma även om det idag är mer ovanligt
                        </li>
                        <li class="li">
                            Skapa levande presentationer i Powerpoint och Keynote
                        </li>
                        <li class="li">
                            Ta fram olika former av illustrationer, grafik och rörliga element för digitala kanaler
                        </li>
                        <li class="li">
                            Kunskap och intresse för rörliga produktioner är önskvärt (Vi jobbar i Adobe Premiere)
                        </li>
                        <li class="li">
                            Löpande kundkontakt
                        </li>
                        <li class="li">
                            Projekt- och ekonomiansvar
                        </li>
                        <li class="li">
                            Koordinering och synkronisering av underleverantörer
                        </li>
                    </ul>

                </div>
                <div class="header">Egenskaper</div>
                <div class="text">
                    <em>Din personlighet</em>
                    <ul>
                        <li class="li">
                            Trygg i Adobes produkter som XD, Photoshop och Indesign
                        </li>
                        <li class="li">
                            Erfarenhet av att arbeta i Office 365
                        </li>
                        <li class="li">
                            Trygg i kundsituationer
                        </li>
                        <li class="li">
                            Intresserad av att lära sig ny teknik och utveckla kreativa lösningar, metoder och
                            strategier
                        </li>
                        <li class="li">
                            Självständig, proaktiv och pådrivande i tilldelade uppdrag
                        </li>
                    </ul>
                </div>
                <div class="header">Nästa steg</div>
                <div class="text">Om du har frågor kring tjänsten som Art Director hör du av dig till jonas@wdo.se <br>
                    Din ansökan skickar du till jobb@wdo.se </div>
            </div>








            <div class="item" [@fadein] *ngIf="currentItem == 3">
             <!--    <div class="title">Developer</div> -->
                <div class="header">Background</div>
                <div class="text">
                    You are a developer with several years of experience in running, managing and developing digital projects. In addition to a technical vein, you have a heart that beats a little extra hard for how a digital product looks and feels. We assume that you have worked on major projects, are independent and quality-driven in your work. You thrive in dynamic project groups and work comfortably both internally with our strategists and designers as well as externally in our supplier network consisting of specialists in a variety of areas. Our customers are a mix of private and public sector.
                </div>
                <div class="header">Responsibilities</div>
                <div class="text">

                    <em>Your work assignments.</em>
                    <ul>
                        <li class="li">
                            To be technically responsible for, operate and develop various types of digital productions
                        </li>
                        <li class="li">
                            To define and structure methods and processes and thereby contribute to a standardized production for WDO
                        </li>
                        <li class="li">
                            You work for a more than perfect UX experience in our productions
                        </li>
                        <li class="li">
                            You are primarily interested in what a digital product looks and feels like, but can also develop certain back-end solutions
                        </li>                       
                    </ul>

                    <div class="header">Characteristics</div>
                  
                    <div class="text">
    
                        <em>Your Personality.</em>
                        <ul>
                            <li class="li">
                                High knowledge and long experience in digital production
                            </li>
                            <li class="li">
                                Curiosity and interest in new technology, digital communication and similar solutions
                            </li>
                            <li class="li">
                                Are a team player who thrives in both small and large projects
                            </li>                       
                        </ul>
                    </div>
                    <div class="header">Next step</div>
                    <div class="text">
                        If you have any questions about the position as a Developer at WDO, please contact johan@wdo.se<br>
Send your job application to jobb@wdo.se</div>

                </div>

            </div>










            <div class="item" [@fadein] *ngIf="currentItem == 0">
              <!--   <div class="title">Projektansvarig</div> -->
                <div class="header">Bakgrund</div>
                <div class="text">
                    Du är en erfaren projektledare med goda referenser och case. Vi förutsätter att du arbetat med större kommunikations- och utvecklingsprojekt och har framgångsrik erfarenhet av att leda team med en mix av interna och externa resurser. Våra kunder består av både privata och offentliga aktörer. Du ska ha erfarenhet av att leverera värde och arbeta med båda segmenten.
                </div>
                <div class="header">Tjänsten</div>
                <div class="text">

                    <em>Dina arbetsuppgifter</em>
                    <ul>
                        <li class="li">
                            Att planera och driva projekten framåt
                        </li>
                        <li class="li">
                            Agera teamledare
                        </li>
                        <li class="li">
                            Ansvar för projektens ekonomiska resultat + resultatansvar för enheten
                        </li>
                        <li class="li">
                            Hålla avstämningar med såväl intern personal som leverantörer, entreprenörer och slutkund
                        </li>
                        <li class="li">
                            Bygga fungerande team (se behov, inventera etc)
                        </li>
                        <li class="li">
                            Ansvara för projekt uppfyllnad och leveranser mot kunder
                        </li>
                        <li class="li">
                            Kultivera nya affärsrelationer och utveckla befintliga
                        </li>                       
                    </ul>
                    
                    
                </div>

                <div class="header">Egenskaper</div>
                <div class="text">
                    <em>Din personlighet</em>
                    <ul>
                        <li class="li">
                            Hög kunskap / insikt inom strategisk kommunikation
                            </li>
                        <li class="li">
                            Nyfikenhet och intresse rörande ny teknik, digital kommunikation och liknande lösningar
                        </li>
                        <li class="li">
                            Duktig på att skriva och formulera i ord och text. Både svenska och engelska
                        </li>
                        <li class="li">
                            Hög social kompetens och vilja att presentera, diskutera och föra dialog
                        </li>                       
                    </ul>
                </div>


                <div class="header">Nästa steg</div>
                <div class="text">Om du har frågor kring tjänsten som Projektansvarig hör du av dig till peter@wdo.se <br>
                    Din ansökan skickar du till jobb@wdo.se</div>

            </div>









            <div class="item" [@fadein] *ngIf="currentItem == 1">
                <!-- <div class="title">Formgivare</div> -->
                <div class="header">Bakgrund</div>
                <div class="text">
                    Är du en kreativ, formsäker och noggrann formgivare som är bekväm med att arbeta med olika former av digitala produktioner? Då kan tjänsten som Formgivare hos WDO passa dig perfekt. Vi har en bred palett av kunder inom offentlig och privat verksamhet och därmed en väldigt varierad uppdragsbild. Vi är nyfikna både på vad du har gjort och vem du är, så din portfölj bifogar du självklart din ansökan.
                </div>
                <div class="header">Formgivare</div>
                <div class="text">

                    <em>Dina arbetsuppgifter</em>
                    <ul>
                        <li class="li">
                            Arbeta fram olika former av digitala produkter som hemsidor, appar och tjänster
                        </li>
                        <li class="li">
                            Viss formgivning för tryck kan förekomma även om det idag är mer ovanligt 
                        </li>
                        <li class="li">
                            Skapa levande presentationer i Powerpoint och Keynote 
                        </li>
                        <li class="li">
                            Ta fram olika former av illustrationer, grafik och rörliga element för digitala kanaler 
                        </li>
                        <li class="li">
                            Kunskap och intresse för rörliga produktioner är önskvärt (Vi jobbar i Adobe Premiere) 
                        </li>                       
                    </ul>
                </div>

                <div class="header">Egenskaper</div>
                <div class="text">
                    <em>Din personlighet</em>
                    <ul>
                        <li class="li">
                            Trygg i Adobes produkter som XD, Photoshop och Indesign 
                                                </li>
                        <li class="li">
                            Erfarenhet av att arbeta i Office 365 
                        </li>
                        <li class="li">
                            Trygg i kundsituationer 
                        </li>
                        <li class="li">
                            Intresserad av att lära sig ny teknik och utveckla kreativa lösningar, metoder och strategier 
                        </li>
                        <li class="li">
                            Självständig, proaktiv och pådrivande i tilldelade uppdrag 
                        </li>
                    </ul>
                </div>

                <div class="header">Nästa steg</div>
                <div class="text">Om du har frågor kring tjänsten som Formgivare hör du av dig till jonas@wdo.se <br>
                    Din ansökan skickar du till jobb@wdo.se</div>

            </div>







        </div>
    </div>
</div>