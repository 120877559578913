import { Component, Input, OnInit } from '@angular/core';
import { AppContainerComponent } from '../app-container/app-container.component'
@Component({
  selector: 'app-brand-header',
  templateUrl: './brand-header.component.html',
  styleUrls: ['./brand-header.component.scss']
})
export class BrandHeaderComponent implements OnInit {

  @Input() section: any;

  constructor(private appContainerComponent: AppContainerComponent) { }

  ngOnInit(): void {
  }

  goHome() {
    this.appContainerComponent.hideWork()
  }

}
