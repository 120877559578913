import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollviewService {

/*   scrollY = new BehaviorSubject(0);
  scrollY$ = this.scrollY.asObservable(); */
  
  divView: any;

  setScrollElement(el: any) {
    this.divView = el;
  }

  scrollToTop() {
    this.divView.nativeElement.scrollTop = 0;
  }

  scrollviewPosition: number = 0
  directionReference: number = 0
  directionSensitivity: number = 20
  scrollDirectionDown: boolean = false;
  scrollDirectionUp: boolean = false;
  navbarVisibility: boolean = true;
  navbarMinimized: boolean = false;

  constructor() {}

  updateScrollY(value: number): void {
   /*  this.scrollY.next(value); */
    this.scrollviewPosition = value
    this.detectScrollDirection()
  }

  detectScrollDirection() {
    if (this.scrollviewPosition > (this.directionReference + this.directionSensitivity)) { // Value is incresing and direction is down
      this.scrollDirectionUp = false;
      this.scrollDirectionDown = true;
      this.directionReference = this.scrollviewPosition;
      console.log("scrolling down")
      this.toggleNavbarVisibility()
      this.toggleNavbarMinimize()
    } else if ( this.scrollviewPosition < (this.directionReference - this.directionSensitivity)) {
      this.scrollDirectionUp = true;
      this.scrollDirectionDown = false;
      this.directionReference = this.scrollviewPosition;
      console.log("scrolling up")
      this.toggleNavbarVisibility()
      this.toggleNavbarMinimize()
    } 
  }

  toggleNavbarMinimize() {
    if ( this.scrollviewPosition > 200 ) {
      this.navbarMinimized = true;
    } else {
      this.navbarMinimized = false;
    }
  }

  toggleNavbarVisibility() {
    if ( this.scrollviewPosition < 800 ) {
      this.navbarVisibility = true;
    } else {
      if ( this.scrollDirectionDown ) {
        this.navbarVisibility = false;
      } else {
        this.navbarVisibility = true;
      }
    }
  }

 /*  toggleNavigationBar() {
    if ( this.scrollviewPosition >  ) {

    }
  } */
}
