<div class="page-brand-header">
    <div class="positioner" (click)="goHome()">
        <div class="brand">
            <div class="media">
                <svg xmlns="http://www.w3.org/2000/svg" width="97.434" height="30" viewBox="0 0 97.434 30">
                    <g id="Group_10" data-name="Group 10" transform="translate(-237 -244.966)">
                        <path id="Path_1" data-name="Path 1"
                            d="M-.689,0A9.471,9.471,0,0,1,3.85,1.163a12.534,12.534,0,0,1,3.826,3.2,16.362,16.362,0,0,1,2.641,4.768,17.071,17.071,0,0,1,.988,5.85,16.93,16.93,0,0,1-.988,5.807,16.023,16.023,0,0,1-2.641,4.747A12.69,12.69,0,0,1,3.85,28.723a9.5,9.5,0,0,1-4.538,1.16H-.726A10.551,10.551,0,0,0,.793,27.519a20.387,20.387,0,0,0,1.282-3.425,33.123,33.123,0,0,0,.886-4.265A33.855,33.855,0,0,0,3.3,14.983a34.18,34.18,0,0,0-.336-4.889,33.21,33.21,0,0,0-.886-4.265A20.471,20.471,0,0,0,.793,2.4,11.624,11.624,0,0,0-.726,0Z"
                            transform="translate(290.249 245.083)" fill="#fff" />
                        <path id="Path_2" data-name="Path 2"
                            d="M-722.194-153.306a11.979,11.979,0,0,0-1.143-3.324,13.174,13.174,0,0,0-1.994-2.763,9.814,9.814,0,0,0-3.411-2.323h6.547l5.288-.04a19.884,19.884,0,0,0-2.091,2.7q-.948,1.464-1.658,2.745-.829,1.52-1.538,3m-4.5,12.578-4.655,8.971-13.766-29.881h8.245Zm-16.254,0-4.655,8.971-13.767-29.881h8.245Z"
                            transform="translate(998.365 406.722)" fill="#fff" />
                        <path id="Path_3" data-name="Path 3" d="M0-574.253H63.645v30H0Z"
                            transform="translate(237 819.219)" fill="none" />
                        <path id="Path_1-2" data-name="Path 1"
                            d="M11.276,0A9.482,9.482,0,0,0,6.735,1.163a12.54,12.54,0,0,0-3.829,3.2A16.359,16.359,0,0,0,.263,9.134a17.062,17.062,0,0,0-.989,5.85A16.921,16.921,0,0,0,.263,20.79a16.02,16.02,0,0,0,2.643,4.747,12.7,12.7,0,0,0,3.829,3.185,9.508,9.508,0,0,0,4.541,1.16h.037a10.55,10.55,0,0,1-1.52-2.364,20.379,20.379,0,0,1-1.283-3.425,33.1,33.1,0,0,1-.887-4.265,33.834,33.834,0,0,1-.336-4.846,34.159,34.159,0,0,1,.336-4.889,33.192,33.192,0,0,1,.887-4.265A20.463,20.463,0,0,1,9.794,2.4,11.623,11.623,0,0,1,11.313,0Z"
                            transform="translate(306.689 245.083)" fill="#fff" />
                        <path id="Path_1-3" data-name="Path 1"
                            d="M-.689,0A9.471,9.471,0,0,1,3.85,1.163a12.534,12.534,0,0,1,3.826,3.2,16.362,16.362,0,0,1,2.641,4.768,17.071,17.071,0,0,1,.988,5.85,16.93,16.93,0,0,1-.988,5.807,16.023,16.023,0,0,1-2.641,4.747A12.69,12.69,0,0,1,3.85,28.723a9.5,9.5,0,0,1-4.538,1.16H-.726A10.551,10.551,0,0,0,.793,27.519a20.387,20.387,0,0,0,1.282-3.425,33.123,33.123,0,0,0,.886-4.265A33.855,33.855,0,0,0,3.3,14.983a34.18,34.18,0,0,0-.336-4.889,33.21,33.21,0,0,0-.886-4.265A20.471,20.471,0,0,0,.793,2.4,11.624,11.624,0,0,0-.726,0Z"
                            transform="translate(323.128 244.98)" fill="#fff" />
                    </g>
                </svg>
            </div>
        </div>
        <div class="site">
            <ng-container *ngIf="section.site">
                {{section.site}}
            </ng-container>
            <div class="line"></div>
        </div>
    </div>
</div>