<div class="case-top-video">
    
    <ng-container *ngIf="section.video.source === 'url'">
        <div class="video-wrapper">
            <app-element-video-background [fullscreen]="true" [style]="'progress'" [video]="section.video"></app-element-video-background>
        </div>
    </ng-container>

   <!--  <ng-container *ngIf="section.video.source === 'url'">
        <video autoplay #caseTopVideoPlayer (timeupdate)="setCurrentTime($event)" poster="{{posterUrl}}">
            <source src="{{videoUrl}}" type="video/mp4" />
        </video>
    </ng-container> -->
        
    <ng-container *ngIf="section.video.source === 'youtube'">
        <div class="youtube" *ngIf="safeUrl">
            <iframe [src]="safeUrl" scrolling="no" frameborder="0" allowfullscreen></iframe>    
        </div>
    </ng-container>       

    <div class="shader"></div>
    <div class="preheader font_14_65_Medium_p200" *ngIf="section.preHeader">{{section.preHeader}}</div>
    <div class="title font_110_75_Bold_m15" *ngIf="section.header">{{section.client}}</div>

    <!-- <div class="positions">
       <div class="bar" [style.width]="currentProgress+'%'"></div>
    </div> -->

</div>