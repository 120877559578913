import { Component, OnInit } from '@angular/core';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss'],
  animations: [
    trigger('fadein', [
      state('void', style({transform: 'translateY(30px)', opacity: 0 })),
      state('*', style({transform: 'translateY(0px)', opacity: 1 })),
      transition('void => *', animate('200ms 150ms'))
    ])
  ]
})
export class WorkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  currentItem: number = 0;
  setItem(i: number) {
    this.currentItem = i;
  }
}
