import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-gallery',
  templateUrl: './case-gallery.component.html',
  styleUrls: ['./case-gallery.component.scss']
})
export class CaseGalleryComponent implements OnInit {

  @Input() section: any;

  constructor() { }

  currentIndex = 0;

  timer: any;
  count = 0;

  ngOnInit() {
      this.startSlideshow();
  }

  startSlideshow() {
      this.timer = setInterval(() => {
          if (this.count == 5) {
              this.count = 0;
              this.nextSlide();
          } else {
              this.count = this.count + 1
          }
      }, 1000);
  }

  ngOnDestroy() {
      if (this.timer) {
          clearInterval(this.timer);
      }
  }

  currentSlide = 0;

  showSlide(i: number) {
      this.currentSlide = i;
      this.count = 0;
  }

  previousSlide() {
      if (this.currentSlide == 0 ) {
          this.currentSlide = this.section.slides.length - 1
      } else {
          this.currentSlide = this.currentSlide - 1
      }   
      this.count = 0;
  }

  nextSlide() {
      if (this.section.slides.length - 1 > this.currentSlide ) {
          this.currentSlide = this.currentSlide + 1
      } else {
          this.currentSlide = 0
      }
      this.count = 0;
  }

}
