
<div class="section-top style-{{top.style}}">
    <ng-container *ngIf="top.style === 'one'">
        <div class="pretitle" *ngIf="top.pretitle">{{top.pretitle}}</div>
        <div class="title font_110_75_Bold_m15" *ngIf="top.title">{{top.title}}</div>
        <div class="subtitle font_14_65_Medium_p200" *ngIf="top.subtitle">{{top.subtitle}}</div>
        <div class="description font_22_55_Roman_p20" *ngIf="top.description">{{top.description}}</div>
    </ng-container>

    <ng-container *ngIf="top.style === 'two'">
        <div class="pretitle" *ngIf="top.pretitle">{{top.pretitle}}</div>
        <div class="title font_110_75_Bold_m15" *ngIf="top.title">{{top.title}}</div>
        <div class="subtitle font_14_65_Medium_p200" *ngIf="top.subtitle">{{top.subtitle}}</div>
        <div class="description font_22_55_Roman_p20" *ngIf="top.description">{{top.description}}</div>
        <div class="cta font_22_55_Roman_p20" *ngIf="top.cta">
            <ng-container *ngFor="let cta of top.cta">
                <span><a href="{{cta.uri}}" target="_blank">{{cta.label}}</a></span> 
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="top.style === 'three'">
        <div class="pretitle font_12_55_Roman_p150" *ngIf="top.pretitle">{{top.pretitle}}</div>
        <div class="title font_120_75_Bold_p15" *ngIf="top.title">{{top.title}}</div>
        <div class="subtitle font_14_65_Medium_p200" *ngIf="top.subtitle">{{top.subtitle}}</div>
        <div class="description font_22_55_Roman_p20" *ngIf="top.description">{{top.description}}</div>
    </ng-container>
    
</div>