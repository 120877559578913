import { Component, Input, OnInit } from '@angular/core';

import { AppContainerComponent } from '../app-container/app-container.component'

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {

  @Input() section: any;

  constructor( private appContainerComponent: AppContainerComponent) { }

  ngOnInit(): void {
  }

  showWork() {
    this.appContainerComponent.showWork()
  }

}
