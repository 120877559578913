import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-large-push',
  templateUrl: './large-push.component.html',
  styleUrls: ['./large-push.component.scss']
})
export class LargePushComponent implements OnInit {

  @Input() section: any;

  constructor() { }

  ngOnInit(): void {
  }

  proto = {
    cover: '../assets/proto/large-push.jpeg',
    preHeader: 'Nästa berättelse',
    title: 'Skellefteå kraft'
  }
}
