import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor( private router: Router) { }

  navigationVisible: boolean = false;

  goHome() {
    this.navigationVisible = false;
    this.router.navigate(['sida/hem']);
  }

  toggleNavigation() {
    if (this.navigationVisible) {
      this.navigationVisible = false;
    } else {
      this.navigationVisible = true;
    }
    console.log('navigation visibility:' + this.navigationVisible)
  }

  // Default now is dark. Should be fetched from the first page that loads
  contrast: string = 'dark'




  showingPopup = false;

  closePopup() {
    this.showingPopup = false;
    console.log("close the pop")
    this.currentPop = {}
  }

  currentPop:any = {}

  openPopup(pop: any) {
    this.currentPop = pop;
    this.showingPopup = true;
  }



  showingPanes = false;

  showPanes() {
    this.showingPanes = true;
  }

  hidePanes() {
    this.showingPanes = false;
  }


  protoPageIndex = 0;

  currentPage: any = {}

  goTo(id: string) { // This should be refactored to get page from server later on
    console.log("sdfsdf happened")
    let s = id;
    // Find document id in local documents
    for (let document of this.pages) {
      if ( document.id === id) {
        console.log("Found a document")
        console.log(document)
        this.currentPage = document;     
        this.contrast = document.styles.contrast;
        break;
      }
    }  
  }

  entryUri: string = '';

  pages: any;


  pagesXXX: any = [




    // Documents after this has been moved and are only here for reference during dev
    //
    //
    //
    //////////////////////////////////////////////////////////////////////////////////



    // CASE PAGE


    // HOME PAGE



    {
      styles: {
        contrast: 'bright'
      },
      sections: [              
        {
          type: 'landingpageCover',
          top: {
            title: 'Vi är en strategi-, innovations- och designpartner.',
            subtitle: 'Introduktion',
            description: 'Att skapa värde för våra uppdragsgivare, deras kunder och samhällert i stort är vårt mål.. lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum',
            style: 'one'
          },
        },
       
        {
          type: 'cases',
          top: {
            title: 'Vad vi gjort.',
            style: 'three'
          },
          cases: [
            {
              client: 'Skellefteå kommun',
              title: 'En plats som växer och utvecklas',
              image: 'henrik-donnestad-t2Sai-AqIpI-unsplash.jpg'
            },
            {
              client: 'Creative Summit',
              title: 'Vår kreativa arena har fyllt 10 år',
              image: 'alice-donovan-rouse-yu68fUQDvOI-unsplash.jpg'
            },
            {
              client: 'Tree hotel',
              title: 'Där naturen och fantasin välkomnar dig',
              image: 'alexander-schimmeck-ineC_oi7NHs-unsplash.jpg'
            },
            {
              client: 'Electrolux',
              title: 'Medarbetardriven innovation',
              image: 'content-pixie-woMD-vhzOHI-unsplash.jpg'
            },
            {
              client: 'Telia',
              title: 'Kommunikation genom innovation',
              image: 'alice-donovan-rouse-yu68fUQDvOI-unsplash.jpg'
            },
            {
              client: 'Sara Kulturhus',
              title: 'Namn och visuell identitet',
              image: 'henrik-donnestad-t2Sai-AqIpI-unsplash.jpg'
            },
          ]
        },
        {
          type: 'teamMembers',
          top: {
            pretitle: 'Det här är WDO',
            title: 'Vårt team.',
            style: 'three'
          },
          members: [
            {
              firstname: 'Jonas',
              lastname: 'Nygren',
              title: 'Creative Director',
              image: 'quinten-de-graaf-Ox1jePviTRQ-unsplash.jpg'
            },
            {
              firstname: 'Nicke',
              lastname: 'Lundmark',
              title: 'Senior Advisor',
              image: 'darius-bashar-oLVNAE-87gA-unsplash.jpg'
            },
            {
              firstname: 'Damian',
              lastname: 'Dugdale',
              title: 'Head of Innovation',
              image: 'darius-bashar-5DPgX66HcAA-unsplash.jpg'
            },
            {
              firstname: 'Peter',
              lastname: 'Mandalh',
              title: 'CEO',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Johan',
              lastname: 'Fahlgren',
              title: 'Strategisk Art Director',
              image: 'quinten-de-graaf-Ox1jePviTRQ-unsplash.jpg'
            },
            {
              firstname: 'Elvira',
              lastname: 'Lundvik',
              title: 'Analytiker',
              image: 'darius-bashar-oLVNAE-87gA-unsplash.jpg'
            },
            {
              firstname: 'Anna',
              lastname: 'Andersson',
              title: 'Projektledare',
              image: 'darius-bashar-5DPgX66HcAA-unsplash.jpg'
            },
            {
              firstname: 'Anna',
              lastname: 'Forsman',
              title: 'Ekonomiansvarig',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Frida',
              lastname: 'Fahlgren',
              title: 'Strateg',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Monika',
              lastname: 'Wassén',
              title: 'Kommunikationsstrateg / Projektledare',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Sandra',
              lastname: 'Adlén',
              title: 'Kommunikationsstrateg / Projektledare',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Carl',
              lastname: 'Wingård',
              title: 'Utvecklare / Projektledare',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Martin',
              lastname: 'Štěpánek',
              title: 'Utvecklare',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Jenny',
              lastname: 'Eklund',
              title: 'Processledning / Samhällsstrategi',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
          ]
        },
        /* {
          type:'mainTitle',
          header:'Vi är en strategi-, innovations- och designpartner.',
           description:'Just nu söker vi dig som vill.. delta this' 
        },
        {
          type:'mediaSlider',
          slides: [
            {
              url:'./assets/images/1.jpg'
            },
            {
              url:'./assets/images/2.jpg'
            },
            {
              url:'./assets/images/3.jpg'
            },
            {
              url:'./assets/images/4.jpg'
            },
            {
              url:'./assets/images/5.jpg'
            },
            {
              url:'./assets/images/6.jpg'
            }
          ]
        }, */
        {
          type: 'pageFooter',
          site: 'Communication'
        }
      ]
    },




    {
      styles: {
        contrast: 'bright'
      },
      sections: [
        {
          type: 'caseTopVideo'
        },
        {
          type: 'caseTopMeta'
        },
        {
          type: 'caseText',
          header: '#1 Foto',
          description: 'Ett urval från Treehotels unika hotellrum. WDO har under åren byggt ett gediget visuellt bibliotek som genom strategiskt arbete fått spridning och publicerats världen över i såväl etablerade magasin som internationella nyhetskanaler. '
        },
        {
          type: 'caseGallery'
        },
        {
          type: 'caseVideoTextSplit',
          header: '#2 Film',
          description: 'Genom rörlig bild förmedlas insikter och förståelse för vad Treehotel verkligen är och står för. Samspelet mellan arkitektur, natur och människan ligger i fokus.'
        },
        {
          type: 'caseArtflow',
          header: '#3 Visuell förmedling',
          description: 'Treehotel är uppbygt kring sampelet mellan natur och arkitekternas tolkningar av rum. En utmaning i sig själt att balansera så pass skilda former och tillämpningar i den övergripande kommunikationen.'
        },        
        {
          type: 'pageFooter',
          site: 'Communication'
        }
      ]
    },



    // ABOUT PAGE

    {
      styles: {
        contrast: 'bright'
      },
      sections: [
        {
          type: 'aboutCover',
          top: {
            title: 'Om WDO.',
            style: 'two'
          }
        },

        {
          type: 'offers',
          top: {
            title: 'Erbjudanden.',
            style: 'two'
          },
          items: [
            {
              label: 'strategi',
              description: 'Det krävs mer än bra produkter eller tjänster för att lyckas. Med en tydlig strategi baserad på insikter och analys hjälper WDO företag och organisationer att utvecklas. Vi utvecklar varumärkes och kommunikationsstrategier för att uppnå förändring. Vårt erbjudande innefattar bland annat: varumärkesstrategier, kommunikationsstrategier och marknadsplaner.'
            },
            {
              label: 'innovation',
              description: 'Vi utvecklar och stärker ert innovationsarbete så att hela organisationens innovationskraft tillvaratas. Tillsammans med vår partner Brightidea erbjuder vi en helhetslösning med metod, verktyg och processledning. Välkommen till WDO Innovation.',

            },
            {
              label: 'kommunikation och design',
              description: 'Varumärken måste vara relevanta, engagerande och pålitliga. Våra erfarna experter hjälper företag och organisationer att få inflytande med strategisk kommunikation. Genom faktabaserat och känslomässigt innehåll med genomarbetad design utvecklar vi varumärken. Vårt erbjudande innefattar bland annat . Bellbird tillägger: insiktsarbete, storytelling och copywriting, formgivning, koncept och kampanjer, skapande av innehåll.',

            },
            {
              label: 'projektledning',
              description: 'Vi tar ansvar för hela resan från planering av projektet till produktion och genomförande. Vi vet att oavsett hur bra en strategi eller design är, så är det genomförandet som avgör dess framgång.',

            },
            {
              label: 'rådgivning',
              description: 'Vi har stor vana av att arbeta med rådgivning till ledare och ledningsgrupper och agerar ofta strategiskt bollplank i frågor som rör kommunikation och innovation.',

            }
          ]
        },
        {
          type: 'aboutVideo'
        },
        {
          type: 'offers',
          top: {
            title: 'Karriär.',
            style: 'two'
          },
          items: [
            {
              label: 'Projektansvarig',
              description: 'Det krävs mer än bra produkter eller tjänster för att lyckas. Med en tydlig strategi baserad på insikter och analys hjälper WDO företag och organisationer att utvecklas. Vi utvecklar varumärkes och kommunikationsstrategier för att uppnå förändring. Vårt erbjudande innefattar bland annat: varumärkesstrategier, kommunikationsstrategier och marknadsplaner.'
            },
            {
              label: 'Strategisk art director',
              description: 'Vi utvecklar och stärker ert innovationsarbete så att hela organisationens innovationskraft tillvaratas. Tillsammans med vår partner Brightidea erbjuder vi en helhetslösning med metod, verktyg och processledning. Välkommen till WDO Innovation.',
              cta: [
                {
                  label: 'Till rekryteringssidan'
                }
              ]
            },
            {
              label: 'Grafisk formgivare',
              description: 'Varumärken måste vara relevanta, engagerande och pålitliga. Våra erfarna experter hjälper företag och organisationer att få inflytande med strategisk kommunikation. Genom faktabaserat och känslomässigt innehåll med genomarbetad design utvecklar vi varumärken. Vårt erbjudande innefattar bland annat . Bellbird tillägger: insiktsarbete, storytelling och copywriting, formgivning, koncept och kampanjer, skapande av innehåll.',
              cta: [
                {
                  label: 'Till rekryteringssidan'
                }
              ]
            },
            {
              label: 'Utvecklare',
              description: 'Vi tar ansvar för hela resan från planering av projektet till produktion och genomförande. Vi vet att oavsett hur bra en strategi eller design är, så är det genomförandet som avgör dess framgång.',
              cta: [
                {
                  label: 'Till rekryteringssidan'
                }
              ]
            }
          ]
        },
        /* {
          type: 'landingpageCover',
          top: {
            title: 'Vi är en strategi-, innovations- och designpartner.',
            subtitle: 'Introduktion',
            description: 'Att skapa värde för våra uppdragsgivare, deras kunder och samhällert i stort är vårt mål.. lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum',
            style: 'one'
          },
        },
        {
          type: 'cases',
          top: {
            title: 'Vad vi gjort.',
            style: 'three'
          },
          cases: [
            {
              client: 'Skellefteå kommun',
              title: 'En plats som växer och utvecklas',
              image: 'henrik-donnestad-t2Sai-AqIpI-unsplash.jpg'
            },
            {
              client: 'Creative Summit',
              title: 'Vår kreativa arena har fyllt 10 år',
              image: 'alice-donovan-rouse-yu68fUQDvOI-unsplash.jpg'
            },
            {
              client: 'Tree hotel',
              title: 'Där naturen och fantasin välkomnar dig',
              image: 'alexander-schimmeck-ineC_oi7NHs-unsplash.jpg'
            },
            {
              client: 'Electrolux',
              title: 'Medarbetardriven innovation',
              image: 'content-pixie-woMD-vhzOHI-unsplash.jpg'
            },
            {
              client: 'Telia',
              title: 'Kommunikation genom innovation',
              image: 'alice-donovan-rouse-yu68fUQDvOI-unsplash.jpg'
            },
            {
              client: 'Sara Kulturhus',
              title: 'Namn och visuell identitet',
              image: 'henrik-donnestad-t2Sai-AqIpI-unsplash.jpg'
            },
          ]
        }, */
        {
          type: 'teamMembers',
          top: {
            /*   pretitle: 'Det här är WDO', */
            title: 'Vårt team.',
            description: 'Vårt team har lång erfarenhet av att arbeta med kommunikation, varumärken samt ledning och affärsutveckling. Vårt nätverk omfattar ett varierat team av projektledare, kreatörer, designers, producenter, affärsutvecklare, lobbyister, teknologer och programmerare. Genom att samarbeta med vårt professionella nätverk kan vi vara lika små eller så stora som vi behöver för att skapa den förändring vi vill.',
            style: 'two'
          },
          members: [
            {
              firstname: 'Jonas',
              lastname: 'Nygren',
              title: 'Creative Director',
              image: 'quinten-de-graaf-Ox1jePviTRQ-unsplash.jpg'
            },
            {
              firstname: 'Nicke',
              lastname: 'Lundmark',
              title: 'Senior Advisor',
              image: 'darius-bashar-oLVNAE-87gA-unsplash.jpg'
            },
            {
              firstname: 'Damian',
              lastname: 'Dugdale',
              title: 'Head of Innovation',
              image: 'darius-bashar-5DPgX66HcAA-unsplash.jpg'
            },
            {
              firstname: 'Peter',
              lastname: 'Mandalh',
              title: 'CEO',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Johan',
              lastname: 'Fahlgren',
              title: 'Strategisk Art Director',
              image: 'quinten-de-graaf-Ox1jePviTRQ-unsplash.jpg'
            },
            {
              firstname: 'Elvira',
              lastname: 'Lundvik',
              title: 'Analytiker',
              image: 'darius-bashar-oLVNAE-87gA-unsplash.jpg'
            },
            {
              firstname: 'Anna',
              lastname: 'Andersson',
              title: 'Projektledare',
              image: 'darius-bashar-5DPgX66HcAA-unsplash.jpg'
            },
            {
              firstname: 'Anna',
              lastname: 'Forsman',
              title: 'Ekonomiansvarig',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Frida',
              lastname: 'Fahlgren',
              title: 'Strateg',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Monika',
              lastname: 'Wassén',
              title: 'Kommunikationsstrateg / Projektledare',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Sandra',
              lastname: 'Adlén',
              title: 'Kommunikationsstrateg / Projektledare',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Carl',
              lastname: 'Wingård',
              title: 'Utvecklare / Projektledare',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Martin',
              lastname: 'Štěpánek',
              title: 'Utvecklare',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Jenny',
              lastname: 'Eklund',
              title: 'Processledning / Samhällsstrategi',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
          ]
        },
        {
          type: 'pageFooter',
          site: 'Communication'
        }
      ]
    },

    {
      styles: {
        contrast: 'bright'
      },
      sections: [
        {
          type: 'brandHeader',
          site: 'Communication'
        },
        {
          type: 'aboutCover',
          top: {
            title: 'Om WDO.',
            style: 'two'
          }
        },

        {
          type: 'offers',
          top: {
            title: 'Karriär.',
            style: 'two'
          },
        },
        {
          type: 'aboutVideo'
        },
        {
          type: 'landingpageCover',
          top: {
            title: 'Vi är en strategi-, innovations- och designpartner.',
            subtitle: 'Introduktion',
            description: 'Att skapa värde för våra uppdragsgivare, deras kunder och samhällert i stort är vårt mål.. lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum',
            style: 'one'
          },
        },
        {
          type: 'cases',
          top: {
            title: 'Vad vi gjort.',
            style: 'three'
          },
          cases: [
            {
              client: 'Skellefteå kommun',
              title: 'En plats som växer och utvecklas',
              image: 'henrik-donnestad-t2Sai-AqIpI-unsplash.jpg'
            },
            {
              client: 'Creative Summit',
              title: 'Vår kreativa arena har fyllt 10 år',
              image: 'alice-donovan-rouse-yu68fUQDvOI-unsplash.jpg'
            },
            {
              client: 'Tree hotel',
              title: 'Där naturen och fantasin välkomnar dig',
              image: 'alexander-schimmeck-ineC_oi7NHs-unsplash.jpg'
            },
            {
              client: 'Electrolux',
              title: 'Medarbetardriven innovation',
              image: 'content-pixie-woMD-vhzOHI-unsplash.jpg'
            },
            {
              client: 'Telia',
              title: 'Kommunikation genom innovation',
              image: 'alice-donovan-rouse-yu68fUQDvOI-unsplash.jpg'
            },
            {
              client: 'Sara Kulturhus',
              title: 'Namn och visuell identitet',
              image: 'henrik-donnestad-t2Sai-AqIpI-unsplash.jpg'
            },
          ]
        },
        {
          type: 'teamMembers',
          top: {
            pretitle: 'Det här är WDO',
            title: 'Vårt team.',
            style: 'three'
          },
          members: [
            {
              firstname: 'Jonas',
              lastname: 'Nygren',
              title: 'Creative Director',
              image: 'quinten-de-graaf-Ox1jePviTRQ-unsplash.jpg'
            },
            {
              firstname: 'Nicke',
              lastname: 'Lundmark',
              title: 'Senior Advisor',
              image: 'darius-bashar-oLVNAE-87gA-unsplash.jpg'
            },
            {
              firstname: 'Damian',
              lastname: 'Dugdale',
              title: 'Head of Innovation',
              image: 'darius-bashar-5DPgX66HcAA-unsplash.jpg'
            },
            {
              firstname: 'Peter',
              lastname: 'Mandalh',
              title: 'CEO',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Johan',
              lastname: 'Fahlgren',
              title: 'Strategisk Art Director',
              image: 'quinten-de-graaf-Ox1jePviTRQ-unsplash.jpg'
            },
            {
              firstname: 'Elvira',
              lastname: 'Lundvik',
              title: 'Analytiker',
              image: 'darius-bashar-oLVNAE-87gA-unsplash.jpg'
            },
            {
              firstname: 'Anna',
              lastname: 'Andersson',
              title: 'Projektledare',
              image: 'darius-bashar-5DPgX66HcAA-unsplash.jpg'
            },
            {
              firstname: 'Anna',
              lastname: 'Forsman',
              title: 'Ekonomiansvarig',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Frida',
              lastname: 'Fahlgren',
              title: 'Strateg',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Monika',
              lastname: 'Wassén',
              title: 'Kommunikationsstrateg / Projektledare',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Sandra',
              lastname: 'Adlén',
              title: 'Kommunikationsstrateg / Projektledare',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Carl',
              lastname: 'Wingård',
              title: 'Utvecklare / Projektledare',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Martin',
              lastname: 'Štěpánek',
              title: 'Utvecklare',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
            {
              firstname: 'Jenny',
              lastname: 'Eklund',
              title: 'Processledning / Samhällsstrategi',
              image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
            },
          ]
        },
        /* {
          type:'mainTitle',
          header:'Vi är en strategi-, innovations- och designpartner.',
           description:'Just nu söker vi dig som vill.. delta this' 
        },
        {
          type:'mediaSlider',
          slides: [
            {
              url:'./assets/images/1.jpg'
            },
            {
              url:'./assets/images/2.jpg'
            },
            {
              url:'./assets/images/3.jpg'
            },
            {
              url:'./assets/images/4.jpg'
            },
            {
              url:'./assets/images/5.jpg'
            },
            {
              url:'./assets/images/6.jpg'
            }
          ]
        }, */
        {
          type: 'pageFooter',
          site: 'Communication'
        }
      ]
    }

  ]



}
