import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-media-slider',
  templateUrl: './media-slider.component.html',
  styleUrls: ['./media-slider.component.scss']
})
export class MediaSliderComponent implements OnInit {

  
  @Input() section: any;

  constructor() { }


  timer: any;
  count = 0;

  ngOnInit() {
      this.startSlideshow();
  }

  startSlideshow() {
      this.timer = setInterval(() => {
          if (this.count == 5) {
              this.count = 0;
              this.nextSlide();
          } else {
              this.count = this.count + 1
          }
      }, 1000);
  }

  ngOnDestroy() {
      if (this.timer) {
          clearInterval(this.timer);
      }
  }

  currentSlide = 0;

  showSlide(i: number) {
      this.currentSlide = i;
      this.count = 0;
  }

  previousSlide() {
      if (this.currentSlide == 0 ) {
          this.currentSlide = 3
      } else {
          this.currentSlide = this.currentSlide - 1
      }   
      this.count = 0;
  }

  nextSlide() {
      if (this.slides.length - 1 > this.currentSlide ) {
          this.currentSlide = this.currentSlide + 1
      } else {
          this.currentSlide = 0
      }
      this.count = 0;
  }

  slides: any = [
      {
          url: './assets/images/1.jpg'
      },
      {
          url: './assets/images/2.jpg'
      },
      {
          url: './assets/images/3.jpg'
      },
      {
          url: './assets/images/4.jpg'
      },
      {
          url: './assets/images/5.jpg'
      },
      {
          url: './assets/images/6.jpg'
      }
  ]



}
