import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from'../shared/services/navigation.service'

@Component({
  selector: 'app-about-video',
  templateUrl: './about-video.component.html',
  styleUrls: ['./about-video.component.scss']
})
export class AboutVideoComponent implements OnInit {

  @Input() section: any;

  constructor( private navigationService : NavigationService ) { }

  ngOnInit(): void {
  }

  openPopup() {
    let pop = {
      type: 'youtube'
    }
    this.navigationService.openPopup(pop);
  }

}
