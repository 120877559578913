<div class="case-video-text-split">
    <div class="wrapper">
        <div class="positioner">

            <ng-container *ngIf="section.video.source === 'url'">
                <div class="video">
                    <div class="video-sizer">
                        <app-element-video-background [fullscreen]="false" [style]="'x'" [video]="section.video">
                        </app-element-video-background>
                    </div>
                    <div class="br"></div>
                </div>
            </ng-container>

            <ng-container *ngIf="section.video.source === 'youtube'">

                <div class="video">
                    <div class="youtube" *ngIf="safeUrl">
                        <iframe [src]="safeUrl" scrolling="no" frameborder="0" allowfullscreen></iframe>
                    </div>
                    <div class="br"></div>
                </div>

            </ng-container>


            <div class="text">
                <div class="header font_14_65_Medium_p200">{{section.header}}</div>
                <div class="description font_20_55_Roman_p20">{{section.description}}</div>
            </div>
        </div>
    </div>
</div>