<div class="case-artflow">

    <div class="about">
        <div class="header font_14_65_Medium_p200">{{section.header}}</div>
        <div class="description font_20_55_Roman_20">{{section.description}}</div>
    </div>

    <div class="flow-area">

        <ng-container *ngFor="let item of section.items; let i = index">
            <div class="item index-{{i + currentPosition}}">
                <div class="media" [ngStyle]="{'background-image': 'url( ' + item.image + ')'}"></div>
            </div>
        </ng-container>

        <div class="navigation">
            <div class="previous" (click)="previous()" [ngClass]="{'disabled' : previousDisabled}">
                <div class="arrow">
                    <div class="line">
                        <div class="line-up"></div>
                        <div class="line-down"></div>
                    </div>
                </div>
            </div>
            <div class="next" (click)="next()" [ngClass]="{'disabled' : nextDisabled}">
                <div class="arrow">
                    <div class="line">
                        <div class="line-up"></div>
                        <div class="line-down"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>