import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-photogrid',
  templateUrl: './case-photogrid.component.html',
  styleUrls: ['./case-photogrid.component.scss']
})
export class CasePhotogridComponent implements OnInit {

  @Input() section: any;

  constructor() { }

  ngOnInit(): void {
  }

}
