import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  showingMember: number = -1;

  showMember(i: number) {
    this.showingMember = i;
  }

  hideMember() {
    this.showingMember = -1;
    console.log("Hide this member")
  }

  protoTeam = [
    {
      name: 'peter',
      lastname: 'pepersson',
      cover: '../assets/proto/team-1.jpeg'
    },
    {
      name: 'nicklas',
      lastname: 'svensson',
      cover: '../assets/proto/team-2.jpeg'
    },
    {
      name: 'anna',
      lastname: 'linderman',
      cover: '../assets/proto/team-3.jpeg'
    },
    {
      name: 'elvira',
      lastname: 'carlsberg',
      cover: '../assets/proto/team-4.jpeg'
    },
    {
      name: 'peter',
      lastname: 'mariestadsson',
      cover: '../assets/proto/team-5.jpeg'
    },
    {
      name: 'nicklas',
      lastname: 'tripple',
      cover: '../assets/proto/team-6.jpeg'
    },
    {
      name: 'anna',
      lastname: 'ipasson',
      cover: '../assets/proto/team-7.jpeg'
    },
    {
      name: 'elvira',
      lastname: 'guiness',
      cover: '../assets/proto/team-8.jpeg'
    }
  ]

}
