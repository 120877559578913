import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../shared/services/navigation.service'

@Component({
  selector: 'app-popup-privacy',
  templateUrl: './popup-privacy.component.html',
  styleUrls: ['./popup-privacy.component.scss']
})
export class PopupPrivacyComponent implements OnInit {

  constructor(public navigationService: NavigationService) { }

  ngOnInit(): void {
  }

}
