<div class="element-video-background" [class.fullscreen]="fullscreen">
    <video autoplay playsinline muted #caseTopVideoPlayer id="{{playerId}}" #myVideo (ended)="onEnded()" (timeupdate)="setCurrentTime($event)"
        poster="{{posterUrl}}">
        <source src="{{videoUrl}}" type="video/mp4" />
    </video>

    <div class="positions" *ngIf="style === 'progress'">
        <div class="positioner">
            <div class="bar">
                <div class="progress" [style.width]="currentProgress+'%'"></div>
            </div>
        </div>
    </div>
</div>