import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-image-text-splash',
  templateUrl: './case-image-text-splash.component.html',
  styleUrls: ['./case-image-text-splash.component.scss']
})
export class CaseImageTextSplashComponent implements OnInit {

  @Input() section: any;

  constructor() { }

  ngOnInit(): void {
  }

}
