<div class="app-team-members">
    <div class="positioner">

        <app-section-top [top]="section.top" *ngIf="section.top"></app-section-top>
        <!--        <div class="header">{{section.header}}</div>
        <div class="description">{{section.description}}</div> -->

        <div class="members">

            <ng-container *ngFor="let member of section.members; let i = index">
                <div class="team-member">
                    <div class="media" (click)="openPopup(i)">
                        <div class="name font_12_75_Bold_p15">{{member.firstname}}</div>
                        <div class="image" [ngStyle]="{'background-image': 'url( ' + member.image + ')'}"></div>
                        <div class="mask"></div>
                    </div>
                    <div class="information">
                        <div class="who">
                            <div class="name font_12_75_Bold_p15">{{member.firstname}} {{member.lastname}}</div>
                            <div class="title font_12_55_Roman_p150">{{member.title}}</div>
                        </div>
                        <div class="contact">
                            <ng-container *ngIf="member.email">
                                <a href="mailto:{{member.email}}">
                                    <div class="mail">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M498.1 5.629C492.7 1.891 486.4 0 480 0c-5.461 0-10.94 1.399-15.88 4.223l-448 255.1C5.531 266.3-.6875 277.8 .0625 289.1s8.375 22.86 19.62 27.55l103.2 43.01l61.85 146.5C186.2 510.6 189.2 512 191.1 512c2.059 0 4.071-.8145 5.555-2.24l85.75-82.4l120.4 50.16c4.293 1.793 8.5 2.472 12.29 2.472c6.615 0 12.11-2.093 15.68-4.097c8.594-4.828 14.47-13.31 15.97-23.05l64-415.1C513.5 24.72 508.3 12.58 498.1 5.629zM32 288l380.1-217.2l-288.2 255.5L32 288zM200.7 462.3L151.1 344.9l229.5-203.4l-169.5 233.1c-2.906 4-3.797 9.094-2.438 13.84c1.374 4.75 4.844 8.594 9.438 10.41l34.4 13.76L200.7 462.3zM416.7 443.3l-167.7-66.56l225.7-310.3L416.7 443.3z"/></svg>
                                    </div>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="member.phone">
                                <a href="tel:{{member.phone}}">
                                    <div class="phone">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.001" viewBox="0 0 16 16.001">
                                            <path id="Path_89" data-name="Path 89" d="M7.133-3.687,3.958-5.048a1.427,1.427,0,0,0-.56-.114,1.43,1.43,0,0,0-1.109.523L1.268-3.389A9.7,9.7,0,0,1-2.613-7.268l1.25-1.022A1.432,1.432,0,0,0-.839-9.4a1.433,1.433,0,0,0-.115-.561l-1.359-3.172A1.427,1.427,0,0,0-3.626-14a1.416,1.416,0,0,0-.322.038l-2.943.678A1.426,1.426,0,0,0-8-11.9s0,0,0,0A13.887,13.887,0,0,0,5.892,2h0A1.426,1.426,0,0,0,7.286.89l.678-2.944A1.431,1.431,0,0,0,8-2.374,1.428,1.428,0,0,0,7.133-3.687ZM5.833.5A12.39,12.39,0,0,1-6.5-11.834l2.834-.653,1.315,3.069L-4.478-7.677A11.32,11.32,0,0,0,1.678-1.521l1.74-2.128L6.487-2.333Z" transform="translate(8 14.001)"/>
                                          </svg>
                                    </div>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="member.linkedin">
                                <a target="_blank" href="{{member.linkedin}}">
                                    <div class="user">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
                                    </div>
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>


    </div>
</div>