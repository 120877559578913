<div class="app-team">
<div class="team-members">
    <ng-container *ngFor="let member of protoTeam; let i = index">
        <div class="team-member" (click)="showMember(i)" (mouseout)="hideMember()" [ngClass]="{'showing' : (showingMember == i) }">
            <div class="media-wrapper">
                <div class="media" [ngStyle]="{'background-image': 'url( ' + member.cover + ')'}"></div>
            </div>
            <div class="shader"></div>
            <div class="name">{{member.name}}</div>
            <div class="flyout">
                <div class="card">
                    <div class="full-name">{{member.lastname}}</div>
                    <div class="title">Full stack developer</div>
                    <div class="description">tetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptat</div>
                    <div class="email">name@wdo.se</div>
                </div>
            </div>
        </div>
    </ng-container>    
</div>
</div>