<div class="contact-cover">
    <div class="positioner">
        <div class="header font_110_75_Bold_m15">{{section.title}}</div>
        <div class="email font_30_65_Medium_p15"><a href="mailto:{{section.mail}}">{{section.email}}</a></div>
        <div class="phone font_30_65_Medium_p15">{{section.phone}}</div>
    </div>

    <div class="play">
        <div class="arrow">
            <div class="line-up"></div>
            <div class="line-down"></div>
        </div>
    </div>

</div>