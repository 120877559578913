<div class="app-large-push">
    <div class="cover" [ngStyle]="{'background-image': 'url( ' + section.cover + ')'}">
        <!-- <div class="col"></div>
        <div class="col"></div>
        <div class="col"></div>
        <div class="col"></div> -->
    </div>
    <div class="text">
        <div class="positioner" [routerLink]="['/sida', section.uri]">
            <div class="pre-header font_12_55_Roman_p150">{{section.preHeader}}</div>
            <div class="header font_60_75_Bold_p15">{{section.title}}
            <!--     <div class="border"></div>
                <div class="border-right-up"></div>
                <div class="border-right-down"></div> -->

                <div class="arrow">
                    <div class="line">
                        <div class="line-up"></div>
                        <div class="line-down"></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>