import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router'
import { Subscription } from 'rxjs';
import { NavigationService } from '../shared/services/navigation.service';
import { ScrollviewService } from '../shared/services/scrollview.service'
@Component({
  selector: 'app-ghostlink',
  templateUrl: './ghostlink.component.html',
  styleUrls: ['./ghostlink.component.scss']
})
export class GhostlinkComponent implements OnInit {

  /////////////////////////////////
  //
  //
  //    The ghostlink component uses an transparent (empty) router to get the uri of what to show
  //    This way we can use the router without being limited to showing the content in the router outlet
  //

  constructor(
    private _navigationService: NavigationService,
    private _activatedRoute: ActivatedRoute,
    private _scrollviewService: ScrollviewService
  ) {
    this._activatedRoute.paramMap.subscribe(params => { // We use this all the time. Do we need to destroy it?
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.getData(); // Solved subscription with ngoninit as sugested by: http://marclloyd.co.uk/angular/reloading-components-when-route-parameters-change-in-angular/
    // Do something else
  }

  currentUri: any = ''
  externalLink: string = ''

  getData() {
    // Get some data
    console.log("parameter changed")
    console.log(this._activatedRoute.snapshot.paramMap.get('id'))
    console.log("yyy happened")

    // Get the param / id

    let param: any = this._activatedRoute.snapshot.paramMap.get('id');
    console.log("ooo happened")


    // Direct link check. If there is no loaded pages, then the user has entered a direkt link. 
    // We store this as a special parameter, as this is only entered on page load
    // This way, when the app has loaded all the scripts, and then fetched the pages we know that we should not load
    // The home page, but the link that we saved

    if (this._navigationService.pages) { // Pages existed so the app is already active, dont change the initial link. Just do the logic
      // Check if the link is external. Must contain http

      if (this.checkIfExternal(param)) {
        this.externalLink = param;
        window.open(this.externalLink, '_blank');
        console.log("ppp happened")

      } else {

        this.currentUri = param;
        this._navigationService.goTo(this.currentUri);
        this._scrollviewService.scrollToTop();
        console.log("xxx happened")
      }
    } else {
      console.log("Set the entryUri to " + param)
      this._navigationService.entryUri = param;
    }


    // Local links    
    console.log("qqq happened")

  }


  goExternal() {

  }

  checkIfExternal(string: any) {
    if (string.split("http://").length > 1) {
      return true;
    } else if (string.split("https://").length > 1) {
      return true;
    } else {
      return false;
    }
  }



}