<div id="app-container">
    <div class="page-container" #myDiv (scroll)="onContainerScroll($event)" *ngIf="protoPage.sections">

        <!-- <app-case-top-video></app-case-top-video>
        
        <app-case-top-gallery></app-case-top-gallery>

        <app-case-top-meta></app-case-top-meta>

        <app-case-gallery></app-case-gallery>

        <app-case-artflow></app-case-artflow>

        <app-case-media></app-case-media> -->

        <!-- <ng-container *ngFor="let section of navigationService.pages[navigationService.protoPageIndex].sections">  -->

        <ng-container *ngIf="navigationService.currentPage.id?.length">
            <ng-container *ngFor="let section of navigationService.currentPage.sections">
                <div class="section-wrapper">
                    <app-case-top-video *ngIf="section.type == 'caseTopVideo'" [section]="section"></app-case-top-video>
                    <app-case-top-meta *ngIf="section.type == 'caseTopMeta'" [section]="section"></app-case-top-meta>
                    <app-case-text *ngIf="section.type == 'caseText'" [section]="section"></app-case-text>
                    <app-case-gallery *ngIf="section.type == 'caseGallery'" [section]="section"></app-case-gallery>
                    <app-case-artflow *ngIf="section.type == 'caseArtflow'" [section]="section"></app-case-artflow>
                    <app-case-video-text-split *ngIf="section.type == 'caseVideoTextSplit'" [section]="section">
                    </app-case-video-text-split>
                    <app-about-cover *ngIf="section.type == 'aboutCover'" [section]="section"></app-about-cover>
                    <app-landingpage-cover *ngIf="section.type == 'landingpageCover'" [section]="section">
                    </app-landingpage-cover>
                    <app-about-video *ngIf="section.type == 'aboutVideo'" [section]="section"></app-about-video>
                    <app-offers *ngIf="section.type == 'offers'" [section]="section"></app-offers>
                    <app-cases *ngIf="section.type == 'cases'" [section]="section"></app-cases>
                    <app-team-members *ngIf="section.type == 'teamMembers'" [section]="section"></app-team-members>
                    <app-page-header *ngIf="section.type == 'pageHeader'"></app-page-header>
                    <app-work *ngIf="section.type == 'work'"></app-work>
                    <app-brand-header *ngIf="section.type == 'brandHeader'" [section]="section"></app-brand-header>
                    <app-page-title *ngIf="section.type == 'mainTitle'" [section]="section"></app-page-title>
                    <app-media-slider *ngIf="section.type == 'mediaSlider'" [section]="section"></app-media-slider>
                    <app-contact-cover *ngIf="section.type == 'contactCover'" [section]="section"></app-contact-cover>
                    <app-video *ngIf="section.type == 'video'"></app-video>
                    <app-page-cover *ngIf="section.type == 'pageCover'"></app-page-cover>
                    <app-large-push *ngIf="section.type == 'largePush'" [section]="section"></app-large-push>
                    <app-text-content *ngIf="section.type == 'textContent'"></app-text-content>
                    <app-showcase *ngIf="section.type == 'showcase'"></app-showcase>
                    <app-section-header *ngIf="section.type == 'sectionHeader'"></app-section-header>
                    <app-team *ngIf="section.type == 'team'"></app-team>
                    <app-page-footer *ngIf="section.type == 'pageFooter'"></app-page-footer>
                    <app-case-photogrid *ngIf="section.type == 'casePhotogrid'" [section]="section"></app-case-photogrid>
                    <app-case-image-text-splash *ngIf="section.type == 'caseImageTextSplash'" [section]="section"></app-case-image-text-splash>
                    <app-news-letter *ngIf="section.type == 'newsLetter'"></app-news-letter>
                    <app-case-image-text-split *ngIf="section.type == 'caseImageTextSplit'" [section]="section"></app-case-image-text-split>
                    <!-- <div class="edit"></div> -->
                </div>
            </ng-container>
        </ng-container>

    </div>
    <div class="overlays">
        <app-app-navigation *ngIf="navigationService.navigationVisible && showNavigation"></app-app-navigation>
        <app-navigation-bar *ngIf="showNavigation"></app-navigation-bar>
        <app-vertical-lines></app-vertical-lines>
        <app-popup-layer *ngIf="navigationService.showingPopup"></app-popup-layer>
    </div>

</div>