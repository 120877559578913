import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-case-top-video',
  templateUrl: './case-top-video.component.html',
  styleUrls: ['./case-top-video.component.scss']
})
export class CaseTopVideoComponent implements OnInit {

  @Input() section: any;

  constructor( private domSanitizer: DomSanitizer) { }

  safeUrl: any = null;

  public screenWidth: any;
  public screenHeight: any;
       

  ngOnInit(): void {
    
    if ( this.section.video.source === 'youtube') { // Only senitize if its an embed, like youtube
      this.sanitizeUrl();
    }
    if ( this.section.video.source === 'url') { // Start the video player
      console.log("I found the video")
      console.log(this.section.video.id)
      this.setVideo()
    }
    console.log("Componenet initiated")
  }

  sanitizeUrl() {
    this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('//www.youtube.com/embed/' + this.section.video.id + '?playlist=' + this.section.video.id + '&amp;iv_load_policy=3&amp;enablejsapi=1&amp;disablekb=1&amp;autoplay=1&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;loop=1&amp;wmode=transparent&amp;widgetid=1&amp;mute=1&amp;modestbranding=1')    
  }

  defaultVideoOrientation: string = 'none'
  videoOrientation: string = '';
  hasPortrait: boolean = false;
  hasLandscape: boolean = false;
  hasSquare: boolean = false;

  posterUrl: string = '';
  videoUrl: string = '';

  setVideo() {

    // Generate unique id for player in DOM to listen to

    // Check what the section is set to serve as default. Portrait, landscape or square

    // Check if the section wants responsiveness

    // Check if there is a mobile and a desktop version to serve      
    if ( this.section.video.portrait ) {
      this.hasPortrait = true;
    }
    if ( this.section.video.landscape ) {
      this.hasLandscape = true;
    }
    if ( this.section.video.square ) {
      this.hasSquare = true;
    }

    // Check if we are on mobile or desktop, or rather landscape of portrait
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    if ( this.screenWidth > this.screenHeight && this.hasLandscape ) {
      this.videoOrientation = 'landscape';
      this.videoUrl = this.section.video.landscape.id;
      this.posterUrl = this.section.video.landscape.poster;
    } else if ( this.screenWidth < this.screenHeight && this.hasPortrait ) {
      this.videoOrientation = 'portrait';
      this.videoUrl = this.section.video.portrait.id;
      this.posterUrl = this.section.video.portrait.poster;
    } else if ( this.screenWidth === this.screenHeight && this.hasSquare ) {
      this.videoOrientation = 'square';
      this.videoUrl = this.section.video.square.id;
      this.posterUrl = this.section.video.square.poster;
    }

    console.log("This should show orientation...")
    console.log(this.videoOrientation)
    console.log(window.innerWidth)
    console.log(window.innerHeight)

    // Set the video based on above check

    // Autoplay if possible

    // Listen to position and show scrubbar in UI

  }



 /*  currentTime: number = 0;
  currentDuration: number = 0; */

  currentProgress: number = 0;

/*   onVideoStart(data: any) {
    console.log(data.target.duration)
  } */

  setCurrentTime(data: any) {
  /*   this.currentDuration = data.target.duration;     
    this.currentTime = data.target.currentTime;   */ 
    this.currentProgress = ((data.target.currentTime / data.target.duration) * 100 )
  }

}
