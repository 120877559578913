<div class="landingpage-cover">
    <div class="cover">
        <div class="positioner">

            <app-section-top [top]="section.top" *ngIf="section.top"></app-section-top>

            <a href="https://wdo.teamtailor.com" target="_blank"><div class="work pulse">Vill du jobba med oss?</div></a>
           <!--  <div class="title" *ngIf="section?.title">{{section.title}}</div>
            <div class="description">
                <div class="header" *ngIf="section?.header">{{section.header}}</div>
                <div class="content" *ngIf="section?.content">{{section.content}}</div>
            </div> -->
        </div>  
    </div>
    <div class="fold">
        <div class="positioner">
            <div class="backdrop"></div>    
            <div class="video">    
                <div class="player">

                    <div class="video-wrapper">
                        <app-element-video-background [fullscreen]="false" [style]="'none'" [video]="section.video"></app-element-video-background>
                    </div>

                   <!--  <div class="youtube">
                        <iframe src="//www.youtube.com/embed/4Q4em7fuxWA?playlist=4Q4em7fuxWA&amp;iv_load_policy=3&amp;enablejsapi=1&amp;disablekb=1&amp;autoplay=1&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;loop=1&amp;wmode=transparent&amp;widgetid=1&amp;mute=1&amp;modestbranding=1" frameborder="0" allowfullscreen=""></iframe>
                    </div> -->
                    <div class="shader"></div>
                    <div class="play" (click)="openPopup()">
                        <div class="arrow">
                            <div class="line-up"></div>
                            <div class="line-down"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</div>