import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss']
})
export class SectionHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  proto = {
    preHeader: ' Arbeta hos wdo',
    header: 'karriär',
    url: 'www.google.com'
  }

}
