import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../shared/services/navigation.service'

import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
  animations: [
    trigger('foldAnimation', [
      state('void', style({ height: 0 })),
      state('*', style({ height: '*' })),
      transition('void => *', animate('200ms')),
      transition('* => void', animate('200ms')),
    ])   
  ],
})
export class PageFooterComponent implements OnInit {

  constructor( private navigationService: NavigationService) { }

  ngOnInit() {

  }


  openPopup() {
      let pop = {
       type: 'privacy'      
     }
     this.navigationService.openPopup(pop);
   }

  currentFold = 0

  showFold(i: number) {
    this.currentFold = i
  }

  footer: any = {
    styles: '',
    push: 'Behöver du hjälp med en idé eller ett projekt? delta this',
    social: {
      youtube: 'https://www.youtube.com/channel/UC7Q4xmZosEAe9ILI8aTB4Ug',
      facebook: 'https://sv-se.facebook.com/WDO-131009883626353/',
      linkedin: 'https://se.linkedin.com/company/wdo',
      twitter: 'https://twitter.com/WDODesign',
    },
    contact: {
      description: 'Om du vill kontakta en specifik person på WDO mailar du enklast på fornamn.efternamn@wdo.se',
      email: [
        {
          email: 'hello@wdo.se',
          label: 'hello@wdo.se'
        }
      ],
      phone: '+46 910 - 79 89 00',
      web: [
        {
          label: 'wdoinnovation.com',
          url: 'http://www.wdoinnovation.com/'
        },
        {
          label: 'creativesummit.org',
          url: 'http://www.creativesummit.org/'
        }
      ],
      visit: {
        name: 'WDO',
        street: 'Stationsgatan 7',
        postcode: '931 31',
        city: 'Skellefteå',
        country: 'Sweden'
      }
    }
  }

}
