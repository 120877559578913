import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() { }

  app: any;
  documents: any;

  /* appXXX: any = {
    navigation: { 
      mains: [
        {
          title: 'Hem'
        },
        {
          title: 'Kunder'
        },
        {
          title: 'WDO'
        },
        {
          title: 'Karriär'
        },
        {
          title: 'Kontakt'
        },
      ],
      pushes: [
        {
          title: 'text'
        },
        {
          title: 'text'
        },
        {
          title: 'text'
        },
        {
          title: 'text'
        },
        {
          title: 'text'
        },
      ],
      featured: [
       
        {
          title: 'Skellefteå Kommun'
        },
        {
          title: 'Electrolux'
        },
        {
          title: 'Telia'
        },
        {
          title: 'Creative Summit'
        },
        {
          title: 'Treehotel'
        }
      ],
      family: [
        {
          title: 'WDO Innovation'
        },
        {
          title: 'Creative Summit'
        }      
      ],
      contact: {
        name: 'WDO',
        street: 'Stationsgatan 7',
        postcode: '93131',
        city: 'Skellefteå',
        country: 'Sweden',
        phone: '+46 910 - 79 89 00',
        mail: 'hello@wdo.se'
      }
    }
  } */


  site: any = {
    footer: {
      styles: '',
      push: 'Behöver du hjälp med en idé eller ett projekt? delta this',
      social: {
        facebook: 'https://sv-se.facebook.com/WDO-131009883626353/',
        linkedin: 'https://se.linkedin.com/company/wdo',
        twitter: 'https://twitter.com/WDODesign',
      },
      contact: {
        description: 'Om du vill kontakta en specifik person på WDO mailar du enklast på fornamn.efternamn@wdo.se',
        email: [
          {
            email: 'hello@wdo.com',
            label: 'hello@wdo.com'
          }
        ],
        phone: '+46 910 - 79 89 00',
        web: [
          {
            label: 'wdoinnovation.com',
            url: 'http://www.wdoinnovation.com/'
          },
          {
            label: 'creativesummit.org',
            url: 'http://www.creativesummit.org/'
          }
        ],
        visit: {
          name: 'WDO',
          street: 'Stationsgatan 7',
          postcode: '931 31',
          city: 'Skellefteå',
          country: 'Sweden'
        }
      }
    },
  }


}
