<div class="case-gallery" *ngIf="section.slides?.length">
    <div class="media">
        <ng-container *ngFor="let item of section.slides; let i = index">
            <div class="image" [ngStyle]="{'background-image': 'url( ' + item.image + ')'}" *ngIf="i == currentSlide"></div>
        </ng-container>
    </div>
    <div class="positions">
        <div class="positioner">
            <ng-container *ngFor="let slide of section.slides; let i = index">
                <div class="position" (click)="showSlide(i)" [ngClass]="{'active' : currentSlide == i}">
                    <div class="label" *ngIf="currentSlide < 10">0{{i + 1}}</div>
                    <div class="label" *ngIf="currentSlide > 9">{{i + 1}}</div>
                    <div class="time">
                        <div class="bar"></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="previous" (click)="previousSlide()">
        <div class="arrow">
            <div class="line">
                <div class="line-up"></div>
                <div class="line-down"></div>
            </div>
        </div>
    </div>
    <div class="next" (click)="nextSlide()">
        <div class="arrow">
            <div class="line">
                <div class="line-up"></div>
                <div class="line-down"></div>
            </div>
        </div>
    </div>
</div>