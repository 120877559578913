import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../shared/services/navigation.service'
@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.scss']
})
export class TeamMembersComponent implements OnInit {

  @Input() section: any;

  constructor( private navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  openPopup(i: number) {
   /*  let pop = {
      type: 'teamMembers',
      items: this.section.members,
      selected: i
    }
    this.navigationService.openPopup(pop); */
  }

}
