<div class="app-showcase">
    <div class="cases">
        <ng-container *ngFor="let case of protoCases">
            <div class="case">
                <div class="media" [ngStyle]="{'background-image': 'url( ' + case.cover + ')'}"></div>
                <div class="shader"></div>
                <div class="title">{{case.title}}</div>
            </div>
        </ng-container>
    </div>
</div>