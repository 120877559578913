<div class="news-letter">

    <div class="hide-form">
        <!-- Begin Mailchimp Signup Form -->
        <div id="mc_embed_signup">
            <form action="https://wdo.us2.list-manage.com/subscribe/post?u=b129288fd408817652e83ab7e&amp;id=8a80857004"
                method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
                target="_blank" novalidate>
                <div id="mc_embed_signup_scroll">
                    <h2>Subscribe</h2>
                    <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                    <div class="mc-field-group">
                        <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                        </label>
                        <input type="email" value="{{userEmail}}" name="EMAIL" class="required email" id="mce-EMAIL">
                    </div>
                    <div class="mc-field-group">
                        <label for="mce-FNAME">First Name </label>
                        <input type="text" value="{{userFirstname}}" name="FNAME" class="" id="mce-FNAME">
                    </div>
                    <div class="mc-field-group">
                        <label for="mce-LNAME">Last Name </label>
                        <input type="text" value="{{userLastname}}" name="LNAME" class="" id="mce-LNAME">
                    </div>
                    <div id="mce-responses" class="clear">
                        <div class="response" id="mce-error-response" style="display:none"></div>
                        <div class="response" id="mce-success-response" style="display:none"></div>
                    </div>
                    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text"
                            name="b_b129288fd408817652e83ab7e_8a80857004" tabindex="-1" value=""></div>
                    <div class="clear"><input type="submit" value="Subscribe" name="subscribe"
                            id="mc-embedded-subscribe" class="button"></div>
                </div>
            </form>
        </div>
        <!--End mc_embed_signup-->
    </div>


    <div class="desktop">
        <div class="positioner">
            <div class="header">Nyhetsbrev</div>
            <div class="fields">
                <div class="field">
                    <div class="label"><input placeholder="Förnamn" #firstName
                            (keyup)="onKeyFirstname(firstName.value)"></div>
                </div>
                <div class="field">
                    <div class="label"><input placeholder="Efternamn" #lastname (keyup)="onKeyLastname(lastname.value)">
                    </div>
                </div>
                <div class="field">
                    <div class="label"><input placeholder="Företag"></div><!-- For show -->
                </div>
                <div class="field">
                    <div class="label"><input placeholder="Mail" #email (keyup)="onKeyEmail(email.value)"></div>
                </div>
            </div>
            <label for="mc-embedded-subscribe">
                <div class="send">
                    <div class="arrow">
                        <div class="line">
                            <div class="line-up"></div>
                            <div class="line-down"></div>
                        </div>
                    </div>
                </div>
            </label>
        </div>
    </div>

    <div class="mobile">
        <div class="col">
            <div class="header">
                <div class="text font_60_75_Bold_0">Nyhetsbrev</div>
            </div>
            <div class="forms">
                <div class="field">
                    <div class="label"><input placeholder="Företag"></div><!-- For show -->
                </div>
                <div class="field">
                    <div class="label"><input placeholder="Mail" #emailM (keyup)="onKeyEmail(emailM.value)"></div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="forms">
                <div class="field">
                    <div class="label"><input placeholder="Förnamn" #firstNameM
                            (keyup)="onKeyFirstname(firstNameM.value)"></div>
                </div>
                <div class="field">
                    <div class="label"><input placeholder="Efternamn" #lastnameM
                            (keyup)="onKeyLastname(lastnameM.value)">
                    </div>
                </div>
            </div>
            <label for="mc-embedded-subscribe">
                <div class="send">
                    <div class="arrow">
                        <div class="line">
                            <div class="line-up"></div>
                            <div class="line-down"></div>
                        </div>
                    </div>
                </div>
            </label>
        </div>
    </div>
</div>