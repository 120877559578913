<div class="about-cover">


    <div class="cover">
        <div class="positioner">
            <app-section-top [top]="section.top" *ngIf="section.top"></app-section-top>
        </div>
    </div>
    <div class="fold">
        <div class="positioner">
            <div class="backdrop"></div>
            <div class="video">
                <div class="player" *ngIf="section.video">

                    <app-element-video-background [fullscreen]="false" [style]="'none'" [video]="section.video"></app-element-video-background>

                    <div class="shader"></div>
                    <div class="play" (click)="openPopup()">
                        <div class="arrow">
                            <div class="line-up"></div>
                            <div class="line-down"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>