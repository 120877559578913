<div id="app-navigation">
    <div class="background" [@background]></div>
    <div class="navigation">
        <div class="pushes-wrapper">
            <div class="pushes">
                <!--  <ng-container *ngFor="let push of appService.app.navigation.pushes">
                    <div class="push">{{push.title}}</div>
                </ng-container> -->
                <div class="push hidden"></div>
            </div>
        </div>
        <div class="mains-wrapper" [@slideinone]>
            <div class="mains">
                <ng-container *ngFor="let main of appService.app.navigation.mains">
                    <div class="main" (click)="close()" [routerLink]="['/sida', main.uri]">
                        <div class="label font_30_65_Medium_p15">{{main.title}}<div class="external" *ngIf="main.external">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                    <path id="Path_90" data-name="Path 90"
                                        d="M4.812-4.375H4.375a.438.438,0,0,0-.437.437V.273a.164.164,0,0,1-.164.164h-9.3A.164.164,0,0,1-5.688.273v-9.3a.164.164,0,0,1,.164-.164h4.211a.438.438,0,0,0,.437-.437v-.437a.438.438,0,0,0-.437-.437H-5.688A1.313,1.313,0,0,0-7-9.187V.437A1.313,1.313,0,0,0-5.688,1.75H3.937A1.313,1.313,0,0,0,5.25.437V-3.937A.438.438,0,0,0,4.812-4.375ZM6.672-12.25H2.953a.328.328,0,0,0-.328.328.327.327,0,0,0,.1.232l1.318,1.318L-3.4-2.929a.327.327,0,0,0-.1.232.327.327,0,0,0,.1.232l.619.619a.327.327,0,0,0,.232.1.327.327,0,0,0,.232-.1L5.123-9.288,6.441-7.97a.327.327,0,0,0,.231.1A.328.328,0,0,0,7-8.2v-3.719A.328.328,0,0,0,6.672-12.25Z"
                                        transform="translate(7 12.25)" />
                                </svg>
                            </div>
                        </div>
                        <!--     <div class="border-left"></div>
                        <div class="border-right"></div> -->

                        <div class="arrow">
                            <div class="line">
                                <div class="line-up"></div>
                                <div class="line-down"></div>
                            </div>
                        </div>

                    </div>
                </ng-container>
            </div>
        </div>
        <div class="featured-wrapper" [@slideintwo]>
            <div class="featured">
                <div class="header font_30_65_Medium_p15">Case</div>
                <ng-container *ngFor="let feature of appService.app.navigation.featured">
                    <div class="feature" (click)="close()" [routerLink]="['/sida', feature.uri]">
                        <div class="label font_20_65_Medium_p15">{{feature.title}}</div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="featured-wrapper" [@slideinthree]>
            <div class="featured">
                <div class="header font_30_65_Medium_p15">Familjen</div>
                <ng-container *ngFor="let family of appService.app.navigation.family">
                    <div class="feature" (click)="close()" [routerLink]="['/sida', family.uri]">
                        <div class="label font_20_65_Medium_p15">{{family.title}}</div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                    </div>
                </ng-container>
            </div>
        </div>

        <!--  <div class="contact-wrapper" [@slideinthree]>
            <div class="contact">
                <div class="visit">
                    <div class="row">{{appService.app.navigation.contact.name}}</div>
                    <div class="row">{{appService.app.navigation.contact.street}}</div>
                    <div class="row">{{appService.app.navigation.contact.postcode}} {{appService.app.navigation.contact.city}}</div>
                    <div class="row">{{appService.app.navigation.contact.country}}</div>
                </div>
                <div class="phone">{{appService.app.navigation.contact.phone}}<div class="border"></div>
                </div>
                <div class="mail">{{appService.app.navigation.contact.mail}}<div class="border"></div>
                </div>
            </div>
        </div> -->
    </div>

    <div class="privacy-wrapper">
        <div class="privacy-positioner">
            <div class="privacy"><a href="https://www.notion.so/Integritetspolicy-344fb6856b224eb3a916de60badd9706" target="_blank">GDPR Cookies</a></div>
        </div>
    </div>

    
</div>