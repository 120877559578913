import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../shared/services/navigation.service';

@Component({
  selector: 'app-popup-team-members',
  templateUrl: './popup-team-members.component.html',
  styleUrls: ['./popup-team-members.component.scss']
})
export class PopupTeamMembersComponent implements OnInit {

  constructor( public navigationService: NavigationService) { }

  currentIndex: number = 0;
  length: number = 0;

  ngOnInit(): void {
    this.currentIndex = this.navigationService.currentPop.selected;
    this.length = this.navigationService.currentPop.items.length
    console.log(this.currentIndex)
  }

  next() {
    if (this.currentIndex < this.length - 1) {
      this.currentIndex = this.currentIndex + 1;
    } else {
      this.currentIndex = 0;
    }
  }

  previous() {
   if ( this.currentIndex > 0 ) {
    this.currentIndex = this.currentIndex - 1;
   } else {
     this.currentIndex = this.length - 1;
   }
  }

}
