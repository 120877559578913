import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../shared/services/navigation.service'
import { ScrollviewService } from '../shared/services/scrollview.service'

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {

  constructor(public navigationService: NavigationService, public scrollviewService: ScrollviewService) { }

  ngOnInit(): void {
  }

  goHome() {
    this.navigationService.goHome()
  }

  toggleNavigation() {
    this.navigationService.toggleNavigation()
    console.log('visibility is' + this.scrollviewService.navbarVisibility)
  }

}
