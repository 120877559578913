import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {

  @Input() section: any;

  constructor() { }

  ngOnInit(): void {
  }

  showItem(i: any) {
    this.currentItem = i;
  }

  currentItem = 0;

  proto: any = [
    {
      label: 'strategi',
      description: 'Det krävs mer än bra produkter eller tjänster för att lyckas. Med en tydlig strategi baserad på insikter och analys hjälper WDO företag och organisationer att utvecklas. Vi utvecklar varumärkes och kommunikationsstrategier för att uppnå förändring. Vårt erbjudande innefattar bland annat: varumärkesstrategier, kommunikationsstrategier och marknadsplaner.'    
    },
    {
      label: 'innovation',
      description: 'Vi utvecklar och stärker ert innovationsarbete så att hela organisationens innovationskraft tillvaratas. Tillsammans med vår partner Brightidea erbjuder vi en helhetslösning med metod, verktyg och processledning. Välkommen till WDO Innovation.',
      cta: [
        {
          label: 'Apple now'
        }
      ]
    },
    {
      label: 'kommunikation och design',
      description: 'Varumärken måste vara relevanta, engagerande och pålitliga. Våra erfarna experter hjälper företag och organisationer att få inflytande med strategisk kommunikation. Genom faktabaserat och känslomässigt innehåll med genomarbetad design utvecklar vi varumärken. Vårt erbjudande innefattar bland annat . Bellbird tillägger: insiktsarbete, storytelling och copywriting, formgivning, koncept och kampanjer, skapande av innehåll.',
      cta: [
        {
          label: 'Apple now'
        }
      ]
    },
    {
      label: 'projektledning',
      description: 'Vi tar ansvar för hela resan från planering av projektet till produktion och genomförande. Vi vet att oavsett hur bra en strategi eller design är, så är det genomförandet som avgör dess framgång.',
      cta: [
        {
          label: 'Apple now'
        }
      ]
    },
    {
      label: 'rådgivning',
      description: 'Vi har stor vana av att arbeta med rådgivning till ledare och ledningsgrupper och agerar ofta strategiskt bollplank i frågor som rör kommunikation och innovation.',
      cta: [
        {
          label: 'Apple now'
        }
      ]
    }
  ]

}
