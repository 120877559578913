import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vertical-lines',
  templateUrl: './vertical-lines.component.html',
  styleUrls: ['./vertical-lines.component.scss']
})
export class VerticalLinesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
