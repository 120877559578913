<div class="page-footer">

    <div class="page-footer-positioner">
        <div class="page-footer-top">
            <div class="push">
               <!--  <div class="content" *ngIf="footer.push">{{footer.push}}</div> -->
                 <div class="content font_50_75_Bold_p30">Behöver du hjälp med en idé eller ett projekt?<a href="mailto:hello@wdo.se">hello@wdo.se</a></div> 
            </div>
            <div class="social">
                <div class="links">
                    <a *ngIf="footer.social?.youtube" target="_blank" href="{{footer.social.youtube}}" class="link">
                        <div class="bottom-line"></div>
                        <div class="top-line"></div>
                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube" class="svg-inline--fa fa-youtube fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg>
                    </a>
                    <a *ngIf="footer.social?.facebook" target="_blank" href="{{footer.social.facebook}}" class="link">
                        <div class="bottom-line"></div>
                        <div class="top-line"></div>
                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f"
                            class="svg-inline--fa fa-facebook-f fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512">
                            <path fill="currentColor"
                                d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
                            </path>
                        </svg>
                    </a>
                    <a *ngIf="footer.social?.linkedin" target="_blank" href="{{footer.social.linkedin}}" class="link">
                        <div class="bottom-line"></div>
                        <div class="top-line"></div>
                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin" class="svg-inline--fa fa-linkedin fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path></svg>
                    </a>
                    <a *ngIf="footer.social?.twitter" target="_blank" href="{{footer.social.twitter}}" class="link">
                        <div class="bottom-line"></div>
                        <div class="top-line"></div>
                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter"
                            class="svg-inline--fa fa-twitter fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                            <path fill="currentColor"
                                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
                            </path>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        <div class="page-footer-bottom">
            <div  class="brand">
                <a class="media" href="http://www.wdo.se">
                    <svg xmlns="http://www.w3.org/2000/svg" width="64.605" height="42.932" viewBox="0 0 64.605 42.932">
                        <path id="Path_56" data-name="Path 56"
                            d="M-704.444-149.663a16.969,16.969,0,0,0-1.66-4.757,18.868,18.868,0,0,0-2.9-3.954,14.266,14.266,0,0,0-4.956-3.324h9.514l7.683-.058a28.529,28.529,0,0,0-3.039,3.867q-1.378,2.094-2.409,3.929-1.2,2.176-2.236,4.3m-6.533,18-6.765,12.839-20-42.762h11.981Zm-23.619,0-6.765,12.839-20-42.762h11.981Z"
                            transform="translate(761.365 161.756)" fill="#fff" />
                    </svg>

                </a>
            </div>
            <div class="info">

                <div class="fold-out visit desctop">
                    <div class="fold-out-header font_12_55_Roman_p150">Besök oss <div class="dot"></div>
                    </div>
                    <div class="fold-out-content font_20_65_Medium_p15">
                        <div class="company">{{footer.contact.visit.name}}</div>
                        <div class="street">{{footer.contact.visit.street}}</div>
                        <div class="city">{{footer.contact.visit.postcode}} {{footer.contact.visit.city}}</div>
                        <div class="country">{{footer.contact.visit.country}}</div>
                        <div class="phone">{{footer.contact.phone}}</div>
                        <ng-container *ngIf="footer.contact.email.length">
                            <ng-container *ngFor="let email of footer.contact.email">
                                <a  href="mailto:{{email.label}}" class="mail">{{email.label}}</a>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="fold-out desctop">
                    <div class="fold-out-header font_12_55_Roman_p150">Kontakt<div class="dot"></div>
                    </div>
                    <div class="fold-out-content font_20_65_Medium_p15">{{footer.contact.description}}</div>
                   <!--  <div class="fold-out-content font_20_65_Medium_p15">Om du vill kontakta en specifik person på WDO mailar du enklast på
                        fornamn.efternamn@wdo.se</div> -->
                </div>
                <div class="fold-out desctop" *ngIf="footer.contact.web">
                    <div class="fold-out-header font_12_55_Roman_p150">Se också<div class="dot"></div>
                    </div>
                    <div class="fold-out-content font_20_65_Medium_p15">
                        <ng-container *ngFor="let link of footer.contact.web">
                            <a target="_blank" href="{{link.url}}" target="_blank">{{link.label}}</a>
                        </ng-container>                      
                        <!--   <a target="_blank" href="http://www.wdoinnovation.com" target="_blank">wdoinnovation.com</a>
                        <a target="_blank" href="http://www.creativesummit.org" target="_blank">creativesummit.org</a> -->
                    </div>
                </div>

                <div class="fold-out mobile visit" (click)="showFold(0)" [ngClass]="{'active' : currentFold == 0}">
                    <div class="fold-out-header font_12_55_Roman_p150">Besök oss <div class="dot"></div>
                    </div>
<!--                     <div class="fold-out-content font_20_65_Medium_p15" [@foldAnimation] *ngIf="currentFold == 0">
 -->

                    <div class="fold-out-content font_20_65_Medium_p15" *ngIf="currentFold == 0">
                        <div class="company">{{footer.contact.visit.name}}</div>
                        <div class="street">{{footer.contact.visit.street}}</div>
                        <div class="city">{{footer.contact.visit.postcode}} {{footer.contact.visit.city}}</div>
                        <div class="country">{{footer.contact.visit.country}}</div>
                        <div class="phone">{{footer.contact.phone}}</div>
                        <ng-container *ngIf="footer.contact.email.length">
                            <ng-container *ngFor="let email of footer.contact.email">
                                <a  href="mailto:{{email.url}}" class="mail">{{email.label}}</a>
                            </ng-container>
                        </ng-container>
                        <!--  <div class="company">WDO</div>
                        <div class="street">Stationsgatan 7</div>
                        <div class="city">931 31 Skellefteå</div>
                        <div class="country">Sweden</div>
                        <div class="phone">+46 910 - 79 89 00</div>
                        <a href="mailto:hello@wdo.se" class="mail">hello@wdo.se</a> -->
                    </div>
                </div>
                <div class="fold-out mobile" (click)="showFold(1)" [ngClass]="{'active' : currentFold == 1}">
                    <div class="fold-out-header font_12_55_Roman_p150">Kontakt<div class="dot"></div>
                    </div>
                    <div class="fold-out-content font_20_65_Medium_p15 " [@foldAnimation] *ngIf="currentFold == 1">{{footer.contact.description}}</div>
                </div>
                <div class="fold-out mobile" (click)="showFold(2)" [ngClass]="{'active' : currentFold == 2}">
                    <div class="fold-out-header font_12_55_Roman_p150">Se också<div class="dot"></div>
                    </div>
                    <div class="fold-out-content font_20_65_Medium_p15" [@foldAnimation] *ngIf="currentFold == 2">
                        <ng-container *ngFor="let link of footer.contact.web">
                            <a target="_blank" href="{{link.url}}" target="_blank">{{link.label}}</a>
                        </ng-container>                              
                    </div>
                </div>
            </div>
            <div class="mobile-brand">
                <a class="media" href="http://www.wdo.se" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="64.605" height="42.932" viewBox="0 0 64.605 42.932">
                        <path id="Path_56" data-name="Path 56"
                            d="M-704.444-149.663a16.969,16.969,0,0,0-1.66-4.757,18.868,18.868,0,0,0-2.9-3.954,14.266,14.266,0,0,0-4.956-3.324h9.514l7.683-.058a28.529,28.529,0,0,0-3.039,3.867q-1.378,2.094-2.409,3.929-1.2,2.176-2.236,4.3m-6.533,18-6.765,12.839-20-42.762h11.981Zm-23.619,0-6.765,12.839-20-42.762h11.981Z"
                            transform="translate(761.365 161.756)" fill="#fff" />
                    </svg>
                </a>
            </div>
        </div>
    </div>

    <div class="privacy-wrapper">
        <div class="privacy-positioner">
            <div class="privacy"><a href="https://www.notion.so/Integritetspolicy-344fb6856b224eb3a916de60badd9706" target="_blank">GDPR Cookies</a></div>
        </div>
    </div>
</div>