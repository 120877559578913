<div class="image-text-split">
    <div class="wrapper">
        <div class="col">
            <div class="media" *ngIf="!section.video" [ngStyle]="{'background-image': 'url( ' + section.image + ')'}"></div>           
            <div class="media" *ngIf="section.video">
                <div class="video">
                    <app-element-video-background [fullscreen]="false" [style]="'x'" [video]="section.video">
                    </app-element-video-background>   
                </div>                            
            </div>
        </div>
        <div class="col">
            <div class="positioner">
                <div class="header font_14_65_Medium_p200">{{section.header}}</div>
                <div class="description font_20_55_Roman_p20">{{section.description}}</div>
                <ng-container *ngIf="section.folds && (!expanded)">
                    <div class="expand" (click)="expand()">
                        <div class="arrow">
                            <div class="line">
                                <div class="line-up"></div>
                                <div class="line-down"></div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="section.folds && expanded">
                    <div class="fold font_20_55_Roman_p20" *ngFor="let fold of section.folds">{{fold.description}}</div>
                </ng-container>
            </div>
        </div>
    </div>    
</div>