import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-element-video-background',
  templateUrl: './element-video-background.component.html',
  styleUrls: ['./element-video-background.component.scss']
})
export class ElementVideoBackgroundComponent implements OnInit {

/*   @ViewChild('caseTopVideoPlayer') myDiv: ElementRef | undefined;
 */
  @Input() video: any;

  @Input() style: any;

  @Input() fullscreen: boolean = false;

  @ViewChild('myVideo', { static: true })
  videoPlayer!: ElementRef;

  constructor() { }

  playerId: string = "";

  ngOnInit(): void {
    this.playerId = "player_" + this.uuidv4()
    console.log(this.playerId);
    this.setVideo()
    this.startVideo();
  }

  startVideo(): void {
    this.videoPlayer.nativeElement.muted = "muted";
    this.videoPlayer.nativeElement.play();
  }

  ngAfterViewInit() {
   /*  console.log("This is the mydiv")
    console.log(this.myDiv); */
  }

  vid: any;

  defaultVideoOrientation: string = 'none'
  videoOrientation: string = '';
  hasPortrait: boolean = false;
  hasLandscape: boolean = false;
  hasSquare: boolean = false;

  posterUrl: string = '';
  videoUrl: string = '';


  screenWidth: any;
  screenHeight: any;


  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  

  onEnded() {
    let mainVideo = <HTMLMediaElement>document.getElementById(this.playerId);
    console.log("mainVideo")
    console.log(mainVideo)
    mainVideo.play();
    console.log("Video ended")
  }

  setVideo() {

    this.vid = document.getElementById(this.playerId);
  
    console.log("The video XXXXX")
    console.log(this.videoUrl)
    // Generate unique id for player in DOM to listen to

    // Check what the section is set to serve as default. Portrait, landscape or square

    // Check if the section wants responsiveness

    // Check if there is a mobile and a desktop version to serve      
    if (this.video.portrait) {
      this.hasPortrait = true;
    }
    if (this.video.landscape) {
      this.hasLandscape = true;
    }
    if (this.video.square) {
      this.hasSquare = true;
    }

    // Check if we are on mobile or desktop, or rather landscape of portrait
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    if (this.screenWidth > this.screenHeight && this.hasLandscape) {
      this.videoOrientation = 'landscape';
      this.videoUrl = this.video.landscape.id;
      this.posterUrl = this.video.landscape.poster;
    } else if (this.screenWidth < this.screenHeight && this.hasPortrait) {
      this.videoOrientation = 'portrait';
      this.videoUrl = this.video.portrait.id;
      this.posterUrl = this.video.portrait.poster;
    } else if (this.screenWidth === this.screenHeight && this.hasSquare) {
      this.videoOrientation = 'square';
      this.videoUrl = this.video.square.id;
      this.posterUrl = this.video.square.poster;
    } else if ( this.hasLandscape ) { // Fallbacks that will mostly result in square
      this.videoOrientation = 'landscape';
      this.videoUrl = this.video.landscape.id;
      this.posterUrl = this.video.landscape.poster;
    } else if ( this.hasPortrait ) {
      this.videoOrientation = 'portrait';
      this.videoUrl = this.video.portrait.id;
      this.posterUrl = this.video.portrait.poster;
    } else if ( this.hasSquare ) {
      this.videoOrientation = 'square';
      this.videoUrl = this.video.square.id;
      this.posterUrl = this.video.square.poster;
    }

    console.log("This should show orientation...")
    console.log(this.videoOrientation)
    console.log(window.innerWidth)
    console.log(window.innerHeight)

    // Set the video based on above check

    // Autoplay if possible

    // Listen to position and show scrubbar in UI

  }




  /*  currentTime: number = 0;
   currentDuration: number = 0; */

  currentProgress: number = 0;

  /*   onVideoStart(data: any) {
      console.log(data.target.duration)
    } */

  setCurrentTime(data: any) {
    /*   this.currentDuration = data.target.duration;     
      this.currentTime = data.target.currentTime;   */
    this.currentProgress = ((data.target.currentTime / data.target.duration) * 100)
  }

}
