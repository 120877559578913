import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GhostlinkComponent } from 'src/app/ghostlink/ghostlink.component'
 import { TestrouteComponent } from 'src/app/testroute/testroute.component'
const routes: Routes = [
  
  { path: 'sida', component: GhostlinkComponent},
  { path: 'sida/:id', component: GhostlinkComponent},
  { path: 'page', component: GhostlinkComponent},
  { path: 'page/:id', component: GhostlinkComponent},
  { path: 'pages', component: GhostlinkComponent},
  { path: 'pages/:id', component: GhostlinkComponent},
  { path: 'ghostlink', component: GhostlinkComponent},
  { path: 'ghostlink/:id', component: GhostlinkComponent},
  { path: 'test', component: TestrouteComponent},
  { path: '**', component:  TestrouteComponent}
];

RouterModule.forRoot(routes, { useHash: false })


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // onSameUrlNavigation: 'ignore', // Becaouse we use changes in ghostlink component to trigger navigation, we need it to always change. Thats why we need the router to reload when clicking the link its already on - https://stackoverflow.com/questions/54062232/what-is-the-use-of-onsameurlnavigation-property-in-angular-versions-greater-tha
    onSameUrlNavigation: 'reload',
    useHash: false
  })],
  exports: [RouterModule]
})


export class AppRoutingModule { }
