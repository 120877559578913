import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from '../shared/services/navigation.service'
import { ScrollviewService } from '../shared/services/scrollview.service'
import { Observable } from 'rxjs';

import { AppService } from '../shared/services/app.service'

@Component({
  selector: 'app-app-container',
  templateUrl: './app-container.component.html',
  styleUrls: ['./app-container.component.scss']
})
export class AppContainerComponent implements OnInit {

  /* scrollviewPosition: Observable<number>; */

  constructor(public navigationService: NavigationService, public scrollviewService: ScrollviewService, public appService: AppService) {
    /*  this.scrollviewPosition = this.scrollviewService.scrollY$; */
  }

  // -- This is not used by the scrollview service. It just gets the element and we can use it to get height, witch, etc of the view
  @ViewChild("myDiv") divView!: ElementRef;

  ngAfterViewInit() {
    console.log("THIS IS WHAT WE SHOULD SCROLL TO TOP")
    console.log(this.divView); // --  Uses the above ViewChild
    this.divView.nativeElement.scrollTop = 30;
    console.log(this.divView.nativeElement.scrollHeight)
    this.scrollviewService.setScrollElement(this.divView)
  }

  scrollToTop() {
    this.divView.nativeElement.scrollTop = 0;
  }

  ngOnInit(): void {
    this.protoPage = this.protoPage1 // Legacy. Just clean this up
  }

  onContainerScroll(e: Event) {
    /*  console.log(this.getYPosition(e)) */
    /*  this.scrollviewService.scrollY.next(this.getYPosition(e));    
     console.log(this.scrollviewPosition) */
    this.scrollviewService.updateScrollY(this.getYPosition(e))
    console.log(this.scrollviewService.scrollviewPosition)
  }

  getYPosition(e: Event): number {
    return (e.target as Element).scrollTop;
  }

  showNavigation: boolean = true;

  showWork() {
    this.protoPage = this.protoPage2
  }

  hideWork() {
    this.protoPage = this.protoPage1
  }

  protoPage: any = {
    sections: []
  }


  protoPage1 = {
    sections: [],
    /* sections: [
      {
        type: 'brandHeader',
        site: 'Communication'
      },
      {
        type: 'aboutCover',
        top: {
          title: 'Om WDO.',
          style: 'two'
        }
      },

      {
        type: 'offers',
        top: {
          title: 'Karriär.',
          style: 'two'
        },
      },
      {
        type: 'aboutVideo'
      },
      {
        type: 'landingpageCover',
        top: {
          title: 'Vi är en strategi-, innovations- och designpartner.',
          subtitle: 'Introduktion',
          description: 'Att skapa värde för våra uppdragsgivare, deras kunder och samhällert i stort är vårt mål.. lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum lorump ipsum',
          style: 'one'
        },
      },
      {
        type: 'cases',
        top: {
          title: 'Vad vi gjort.',
          style: 'three'
        },
        cases: [
          {
            client: 'Skellefteå kommun',
            title: 'En plats som växer och utvecklas',
            image: 'henrik-donnestad-t2Sai-AqIpI-unsplash.jpg'
          },
          {
            client: 'Creative Summit',
            title: 'Vår kreativa arena har fyllt 10 år',
            image: 'alice-donovan-rouse-yu68fUQDvOI-unsplash.jpg'
          },
          {
            client: 'Tree hotel',
            title: 'Där naturen och fantasin välkomnar dig',
            image: 'alexander-schimmeck-ineC_oi7NHs-unsplash.jpg'
          },
          {
            client: 'Electrolux',
            title: 'Medarbetardriven innovation',
            image: 'content-pixie-woMD-vhzOHI-unsplash.jpg'
          },
          {
            client: 'Telia',
            title: 'Kommunikation genom innovation',
            image: 'alice-donovan-rouse-yu68fUQDvOI-unsplash.jpg'
          },
          {
            client: 'Sara Kulturhus',
            title: 'Namn och visuell identitet',
            image: 'henrik-donnestad-t2Sai-AqIpI-unsplash.jpg'
          },
        ]
      },
      {
        type: 'teamMembers',
        top: {
          pretitle: 'Det här är WDO',
          title: 'Vårt team.',
          style: 'three'
        },
        members: [
          {
            firstname: 'Jonas',
            lastname: 'Nygren',
            title: 'Creative Director',
            image: 'quinten-de-graaf-Ox1jePviTRQ-unsplash.jpg'
          },
          {
            firstname: 'Nicke',
            lastname: 'Lundmark',
            title: 'Senior Advisor',
            image: 'darius-bashar-oLVNAE-87gA-unsplash.jpg'
          },
          {
            firstname: 'Damian',
            lastname: 'Dugdale',
            title: 'Head of Innovation',
            image: 'darius-bashar-5DPgX66HcAA-unsplash.jpg'
          },
          {
            firstname: 'Peter',
            lastname: 'Mandalh',
            title: 'CEO',
            image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
          },
          {
            firstname: 'Johan',
            lastname: 'Fahlgren',
            title: 'Strategisk Art Director',
            image: 'quinten-de-graaf-Ox1jePviTRQ-unsplash.jpg'
          },
          {
            firstname: 'Elvira',
            lastname: 'Lundvik',
            title: 'Analytiker',
            image: 'darius-bashar-oLVNAE-87gA-unsplash.jpg'
          },
          {
            firstname: 'Anna',
            lastname: 'Andersson',
            title: 'Projektledare',
            image: 'darius-bashar-5DPgX66HcAA-unsplash.jpg'
          },
          {
            firstname: 'Anna',
            lastname: 'Forsman',
            title: 'Ekonomiansvarig',
            image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
          },
          {
            firstname: 'Frida',
            lastname: 'Fahlgren',
            title: 'Strateg',
            image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
          },
          {
            firstname: 'Monika',
            lastname: 'Wassén',
            title: 'Kommunikationsstrateg / Projektledare',
            image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
          },
          {
            firstname: 'Sandra',
            lastname: 'Adlén',
            title: 'Kommunikationsstrateg / Projektledare',
            image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
          },
          {
            firstname: 'Carl',
            lastname: 'Wingård',
            title: 'Utvecklare / Projektledare',
            image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
          },
          {
            firstname: 'Martin',
            lastname: 'Štěpánek',
            title: 'Utvecklare',
            image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
          },
          {
            firstname: 'Jenny',
            lastname: 'Eklund',
            title: 'Processledning / Samhällsstrategi',
            image: 'darius-bashar-m5MkKAg9O3Q-unsplash.jpg'
          },
        ]
      },
      {
        type: 'pageFooter',
        site: 'Communication'
      }
    ] */
  }


  protoPage2 = {
    sections: [
      {
        type: 'brandHeader',
        site: 'Communication'
      },
      {
        type: 'work'
      },
      {
        type: 'pageFooter',
        site: 'Communication'
      }
    ]
  }


}
