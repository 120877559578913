import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { AppService } from 'src/app/shared/services/app.service'
import { NavigationService } from 'src/app/shared/services/navigation.service'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'wdo-web';

  constructor(private httpClient: HttpClient, private appService: AppService, private navigationService: NavigationService){}

  app: any = [];
  pages: any = []

  ngOnInit(){
    this.httpClient.get("assets/app-data.json").subscribe(data =>{
      console.log(data);
      this.app = data;
      this.appService.app = data;
    })
    this.httpClient.get("assets/documents.json?v=2").subscribe(data =>{
      console.log(data);
      this.pages = data;
      this.navigationService.pages = data;
     /*  this.navigationService.goTo('hem'); */

     this.setStartPage(); 
    })
  }

  setStartPage() {
    if ( this.navigationService.entryUri.length ) { // If this is already set we should use it
      console.log("Found something in navigationservice")
      console.log(this.navigationService.currentPage)
      this.navigationService.goTo(this.navigationService.entryUri); // This uri should only be used once so we kan erase it when this function has been triggered
      this.navigationService.entryUri = '';
    } else {
      console.log("Found nothing in navigationservice")
      this.navigationService.goTo('hem');
    }
  }

  // Techiques for local json and offline found here https://www.techiediaries.com/angular-local-json-files/
  
}
