import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

import { Router } from '@angular/router'

import { AppService } from './../shared/services/app.service'

import { NavigationService } from './../shared/services/navigation.service'

@Component({
  selector: 'app-app-navigation',
  templateUrl: './app-navigation.component.html',
  styleUrls: ['./app-navigation.component.scss'],
  animations: [
    trigger('fadein', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition('void => *', animate('200ms')),
      transition('* => void', animate('200ms')),
    ]),
    trigger('background', [
      state('void', style({ transform: 'scale(0.98)' })),
      state('*', style({ transform: 'scale(1)' })),
      transition('void => *', animate('150ms')),
      transition('* => void', animate('150ms')),
    ]),
    trigger('slideinone', [
      state('void', style({ transform: 'translateY(30px)', opacity: 0 })),
      state('*', style({ transform: 'translateY(0px)', opacity: 1 })),
      transition('void => *', animate('150ms 150ms')),
      transition('* => void', animate('200ms 200ms')),
    ]),
    trigger('slideintwo', [
      state('void', style({ transform: 'translateY(30px)', opacity: 0 })),
      state('*', style({ transform: 'translateY(0px)', opacity: 1 })),
      transition('void => *', animate('150ms 200ms')),
      transition('* => void', animate('150ms 300ms')),
    ]),
    trigger('slideinthree', [
      state('void', style({ transform: 'translateY(30px)', opacity: 0 })),
      state('*', style({ transform: 'translateY(0px)', opacity: 1 })),
      transition('void => *', animate('150ms 250ms')),
      transition('* => void', animate('150ms 450ms')),
    ]),
  ],
})


export class AppNavigationComponent implements OnInit {

  constructor(public appService: AppService, public navigationService: NavigationService, private router: Router) { }

  ngOnInit(): void {
  }

  close() {
    this.navigationService.navigationVisible = false;
  }


  openPopup() {
    let pop = {
     type: 'privacy'      
   }
   this.navigationService.openPopup(pop);
   this.navigationService.navigationVisible = false;
 }


  goTo(id: string) {
    this.navigationService.navigationVisible = false;
   /*  this.navigationService.goTo(id); */
  }
  touched() {
    this.navigationService.navigationVisible = false;
  }
 
}
