<div class="app-cases">
    <div class="positioner">

        <div class="top">
            <app-section-top [top]="section.top" *ngIf="section.top"></app-section-top>
        </div>
        <ng-container *ngFor="let case of section.cases">
            <div class="case" [routerLink]="['/sida', case.uri]">
                <div class="media">
                    <div class="image">
                        <div class="size" [ngStyle]="{'background-image': 'url( ' + case.image + ')'}">
                           <!--  <div class="media" [ngStyle]="{'background-image': 'url( ' + item.image + ')'}"></div>

                            <img src="{{case.image}}"> -->
                        </div>
                        <div class="shader"></div>
                    </div>
                    <div class="barndoors">
                        <div class="left-door"></div>
                        <div class="right-door"></div>
                    </div>
                </div>
                <div class="description">
                    <div class="client font_15_65_Medium_p15">{{case.client}}</div>
                    <div class="title font_45_75_Bold_p30">{{case.title}}</div>
                    <div class="arrow">
                        <div class="line">
                            <div class="line-up"></div>
                            <div class="line-down"></div>
                        </div>
                    </div>
                </div>
                <div class="touch"></div>
            </div>
        </ng-container>




    </div>
</div>