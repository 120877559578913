import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-cover',
  templateUrl: './page-cover.component.html',
  styleUrls: ['./page-cover.component.scss']
})
export class PageCoverComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  proto = {
    title: 'Vi är innovatörer som arbetar med kommunikation och samhällsfrågor.',
    cover: '../assets/proto/page-cover.jpeg'
  }
}
