import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from'../shared/services/navigation.service'

@Component({
  selector: 'app-about-cover',
  templateUrl: './about-cover.component.html',
  styleUrls: ['./about-cover.component.scss']
})
export class AboutCoverComponent implements OnInit {

  @Input() section: any;

  constructor(public navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  openPopup() {
    let pop = {
      type: 'youtube',
      id: 'about'
    }
    this.navigationService.openPopup(pop);
  }

}
