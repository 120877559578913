<div class="app-offers">

    <div class="positioner">

        <div class="inverter">
            <app-section-top [top]="section.top" *ngIf="section.top"></app-section-top>
        </div>

        <div class="menu">
            <ng-container *ngFor="let item of section.items; let i = index">
                <ng-container *ngIf="!item.transparent">
                    <ng-container *ngIf="item.external">
                        <div class="item" [routerLink]="['/sida', item.uri]">
                            <div class="num font_12_55_Roman_p150">0{{i + 1}}</div>
                            <div class="label font_12_65_Medium_p15">{{item.label}}</div>
                            <div class="external">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                    <path id="Path_90" data-name="Path 90"
                                        d="M4.812-4.375H4.375a.438.438,0,0,0-.437.437V.273a.164.164,0,0,1-.164.164h-9.3A.164.164,0,0,1-5.688.273v-9.3a.164.164,0,0,1,.164-.164h4.211a.438.438,0,0,0,.437-.437v-.437a.438.438,0,0,0-.437-.437H-5.688A1.313,1.313,0,0,0-7-9.187V.437A1.313,1.313,0,0,0-5.688,1.75H3.937A1.313,1.313,0,0,0,5.25.437V-3.937A.438.438,0,0,0,4.812-4.375ZM6.672-12.25H2.953a.328.328,0,0,0-.328.328.327.327,0,0,0,.1.232l1.318,1.318L-3.4-2.929a.327.327,0,0,0-.1.232.327.327,0,0,0,.1.232l.619.619a.327.327,0,0,0,.232.1.327.327,0,0,0,.232-.1L5.123-9.288,6.441-7.97a.327.327,0,0,0,.231.1A.328.328,0,0,0,7-8.2v-3.719A.328.328,0,0,0,6.672-12.25Z"
                                        transform="translate(7 12.25)" />
                                </svg>
                            </div>
                            <div class="arrow">
                                <div class="line">
                                    <div class="line-up"></div>
                                    <div class="line-down"></div>
                                </div>
                            </div>

                        </div>
                    </ng-container>
                    <ng-container *ngIf="!item.external">
                        <div class="item" (click)="showItem(i)" [ngClass]="{'active' : currentItem == i}">
                            <div class="num font_12_55_Roman_p150">0{{i + 1}}</div>
                            <div class="label font_12_65_Medium_p15">{{item.label}}</div>
                            <div class="arrow">
                                <div class="line">
                                    <div class="line-up"></div>
                                    <div class="line-down"></div>
                                </div>
                            </div>

                            <div class="description font_22_55_Roman_p10" *ngIf="currentItem == i && section.items[i].description">{{section.items[i].description}}</div>

                        </div>
                    </ng-container>                    
                </ng-container>
            </ng-container>
        </div>

        <div class="descriptions">
            <ng-container *ngFor="let item of section.items; let i = index">
                <div class="description" *ngIf="currentItem == i && item.description">{{item.description}}
                    <ng-container *ngIf="item.cta?.length">
                        <div class="ctas">
                            <div class="cta" *ngFor="let cta of item.cta"><a target="_blank"
                                    href="{{cta.uri}}">{{cta.label}}</a></div>
                        </div>
                    </ng-container>
                </div>

            </ng-container>
        </div>
    </div>


</div>