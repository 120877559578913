import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-top-gallery',
  templateUrl: './case-top-gallery.component.html',
  styleUrls: ['./case-top-gallery.component.scss']
})
export class CaseTopGalleryComponent implements OnInit {

  constructor() { }

  currentIndex = 0;

  timer: any;
  count = 0;

  ngOnInit() {
      this.startSlideshow();
  }

  startSlideshow() {
      this.timer = setInterval(() => {
          if (this.count == 5) {
              this.count = 0;
              this.nextSlide();
          } else {
              this.count = this.count + 1
          }
      }, 1000);
  }

  ngOnDestroy() {
      if (this.timer) {
          clearInterval(this.timer);
      }
  }

  currentSlide = 0;

  showSlide(i: number) {
      this.currentSlide = i;
      this.count = 0;
  }

  previousSlide() {
      if (this.currentSlide == 0 ) {
          this.currentSlide = 3
      } else {
          this.currentSlide = this.currentSlide - 1
      }   
      this.count = 0;
  }

  nextSlide() {
      if (this.slides.length - 1 > this.currentSlide ) {
          this.currentSlide = this.currentSlide + 1
      } else {
          this.currentSlide = 0
      }
      this.count = 0;
  }


  slides = [
    {
      image: 'assets/images/Treehotel_Rooms_1.jpg'
    },
    {
      image: 'assets/images/Treehotel_Rooms_2.jpg'
    },
    {
      image: 'assets/images/Treehotel_Rooms_3.jpg'
    },
    {
      image: 'assets/images/Treehotel_Rooms_4.jpg'
    },
    {
      image: 'assets/images/Treehotel_Rooms_5.jpg'
    }    
  ]
}
