import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-top',
  templateUrl: './section-top.component.html',
  styleUrls: ['./section-top.component.scss']
})
export class SectionTopComponent implements OnInit {

  @Input() top: any;

  constructor() { }

  ngOnInit(): void {
  }

}
