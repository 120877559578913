import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss']
})
export class ShowcaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  protoCases = [
    {
      title: 'the industries',
      cover: '../assets/proto/case-1.jpeg'
    },
    {
      title: 'träföretaget',
      cover: '../assets/proto/case-2.jpeg'
    },
    {
      title: 'evelina',
      cover: '../assets/proto/case-3.jpeg'
    },   
    {
      title: 'industrihus',
      cover: '../assets/proto/case-4.jpeg'
    },     
    {
      title: 'kallholmen',
      cover: '../assets/proto/case-6.jpeg'
    },
    {
      title: 'verktygslådan',
      cover: '../assets/proto/case-7.jpeg'
    },
    {
      title: 'the industries',
      cover: '../assets/proto/case-1.jpeg'
    },
    {
      title: 'träföretaget',
      cover: '../assets/proto/case-2.jpeg'
    },
    {
      title: 'evelina',
      cover: '../assets/proto/case-3.jpeg'
    },   
    {
      title: 'industrihus',
      cover: '../assets/proto/case-4.jpeg'
    },     
    {
      title: 'kallholmen',
      cover: '../assets/proto/case-6.jpeg'
    },
    {
      title: 'verktygslådan',
      cover: '../assets/proto/case-7.jpeg'
    }
  ]

}
