import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-image-text-split',
  templateUrl: './case-image-text-split.component.html',
  styleUrls: ['./case-image-text-split.component.scss']
})
export class CaseImageTextSplitComponent implements OnInit {

  @Input() section: any;

  constructor() { }

  ngOnInit(): void {
  }

  expanded: boolean = false;

  expand() {
    this.expanded = true;
  }
}
