import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-case-video-text-split',
  templateUrl: './case-video-text-split.component.html',
  styleUrls: ['./case-video-text-split.component.scss']
})
export class CaseVideoTextSplitComponent implements OnInit {

  @Input() section: any;

  constructor(private domSanitizer : DomSanitizer) { }

  safeUrl: any = null;

  ngOnInit(): void {
    this.sanitizeUrl();
  }

  sanitizeUrl() {
    this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('//www.youtube.com/embed/' + this.section.video.id + '?playlist=' + this.section.video.id + '&amp;iv_load_policy=3&amp;enablejsapi=1&amp;disablekb=1&amp;autoplay=1&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;loop=1&amp;wmode=transparent&amp;widgetid=1&amp;mute=1&amp;modestbranding=1')    
  }

/*   "video": {
    "source": "youtube",
    "id": "7trm4YbdvAw"
}, */


}
