import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-case-artflow',
  templateUrl: './case-artflow.component.html',
  styleUrls: ['./case-artflow.component.scss']
})
export class CaseArtflowComponent implements OnInit {

  @Input() section: any;

  constructor() { }

  ngOnInit(): void {
  }

  currentPosition = 1;
  previousDisabled = false;
  nextDisabled = false;

  previous() {
    if ( this.currentPosition < 3) {
      this.currentPosition = this.currentPosition + 1;
      console.log(this.currentPosition);
      this.nextDisabled = false;
    } 
    if (this.currentPosition == 3) {
      console.log("nope")
      this.previousDisabled = true;
    }
  }

  next() {
    if ( this.currentPosition > -Math.abs(this.section.items.length - 3) ){
      this.currentPosition = this.currentPosition - 1;
      console.log(this.currentPosition);
      this.previousDisabled = false;
    } 
    if (this.currentPosition == -Math.abs(this.section.items.length - 3) )  {
      console.log("nope")
      this.nextDisabled = true;
    } 
  }

}
